import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { Row, Col } from "reactstrap"; // Import Row and Col for grid layout
import "react-datepicker/dist/react-datepicker.css";

const adminId = Cookies.get("id");

const UserDetails = () => {
  const [superpassword, setSuperpassword] = useState("1");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [filter, setFilter] = useState("all"); // Default filter is "all"
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10); // Number of items per page
  const [totalPage, setTotalPage] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [city, setCity] = useState("");
  const [search, setSearch] = useState("");

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
  ];

  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };

  useEffect(() => {
    // This effect will run when the component mounts and anytime adminId changes
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId]);

  useEffect(() => {
    fetchUserDetails();
  }, [filter, currentPage, fromDate, toDate, city, search]);
  
  const fetchUserDetails = () => {
    const requestBody = {
      dateRange: {
        fromDate: fromDate ? fromDate.toISOString().split("T")[0] : "",
        toDate: toDate ? toDate.toISOString().split("T")[0] : "",
      },
      city: city,
      search: search,
      isVerified: 1,
      isBanned: filter === "banned" ? 1 : 0,
      isPremium: filter === "premium" ? 1 : 0,
    };

    // Define the request options, including the body
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    fetch(
      `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/user-details/ekdnejd/fkedbjb?page=${currentPage}&size=${perPage}&isOwner=`,
      requestOptions
    )
      .then((res) => {
        // Check if the response status is OK (200-299)
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json(); // Convert the response to JSON
      })
      .then((data) => {
        setUserDetails(data.users);
        setTotalPage(data.totalPages);
      })
      .catch((err) => console.error("Error fetching user details:", err));
  };

  // Function to handle user click and open the modal
  const handleUserClick = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedUser(null);
    setModalOpen(false);
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(0); // Reset current page when changing filters
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const toggleUserStatus = (userId, currentStatus) => {
    // Determine the new status based on the current status
    const newStatus = currentStatus === 1 ? 0 : 1;

    // Construct the URL with the user ID
    const apiUrl = `${process.env.REACT_APP_API_IP}/user/userBanUnbanToggle/${adminId}/${userId}`;

    // Send a GET request to toggle the status
    fetch(apiUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          // Update the user's status in the local state
          const updatedUserDetails = userDetails.map((user) =>
            user.id === userId ? { ...user, isBanned: newStatus } : user
          );
          setUserDetails(updatedUserDetails);
        } else {
          console.error("Error toggling status.");
        }
      })
      .catch((err) => console.error("Error toggling status:", err));
  };

  // Filter the user list based on the selected filter and pagination
  const filteredUsers = () => {
    let filteredData = userDetails;

    if (filter === "banned") {
      filteredData = filteredData.filter((user) => user.isBanned === 1);
    } else if (filter === "premium") {
      filteredData = filteredData.filter((user) => user.isPremium === 1);
    }

    const startIndex = currentPage * perPage;
    const endIndex = startIndex + perPage;
    return filteredData.slice(startIndex, endIndex);
  };

  // Pagination handlers
  const nextPage = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  const resetFilters = () => {
    setCity(""); // Reset city input
    setSearch(""); // Reset search input
    setFromDate(null); // Reset start date input
    setToDate(null); // Reset end date input
    setFilter("all"); // Reset dropdown filter
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
        <i className="bi bi-people-fill me-2"></i>
        User Details{" "}
      </CardTitle>
      <CardBody style={{ overflowX: "auto" }}>
        <Modal isOpen={showModal} backdrop="static" keyboard={false}>
          <ModalHeader>
            <span>Password Verification </span>
            <Link to="/starter" style={{ paddingLeft: "175px" }}>
              <Button>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </ModalHeader>

          <ModalBody>
            <Form onSubmit={handleModalSubmit}>
              <FormGroup>
                <Label for="superpassword">Password:</Label>
                <Input
                  type="password"
                  id="superpassword"
                  required
                  value={superpassword}
                  onChange={(e) => setSuperpassword(e.target.value)}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
              <Button type="submit">Continue</Button>
            </Form>{" "}
          </ModalBody>
        </Modal>

        {/* Filters */}
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <FormGroup>
              <Label for="city">City:</Label>
              <Input
                type="text"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter city"
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={3}>
            <FormGroup>
              <Label for="search">Search:</Label>
              <Input
                type="text"
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search users"
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={3}>
            <FormGroup>
              <Label for="fromDate">Start Date:</Label>
              <div>
                <DatePicker
                  id="fromDate"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select start date"
                  className="form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col xs={12} md={3}>
            <FormGroup>
              <Label for="toDate">End Date:</Label>
              <div>
                <DatePicker
                  id="toDate"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select end date"
                  className="form-control"
                />
              </div>
            </FormGroup>
          </Col>
          </Row>
          <div className="mb-3 d-flex" style={{justifyContent : "space-between"}}>
          <Col xs={6} md={3}>
          <div className="d-flex mb-3 ">
          <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle caret>Filter Users</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleFilterChange("all")}>All Users</DropdownItem>
              <DropdownItem onClick={() => handleFilterChange("banned")}>Banned Users</DropdownItem>
              <DropdownItem onClick={() => handleFilterChange("premium")}>Premium Users</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
          </Col>
          <Col xs={6} md={3} className="d-flex mb-3 w-auto">
            <Button color="secondary" onClick={resetFilters}>
              Reset Filters
            </Button>
          </Col>
        </div>
        <h1>
          {filter === "all" && <span>All Users</span>}{" "}
          {filter === "banned" && <span>Banned Users</span>}{" "}
          {filter === "premium" && <span>Premium Users</span>}
        </h1>
        <Table className="table-striped" bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>User ID</th>
              <th>Name</th>
              <th>Number</th>
              <th>Is Owner</th>
              <th>Created On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userDetails.map((user, index) => (
              <tr key={user.id} onClick={() => handleUserClick(user)}>
                <td>{currentPage * perPage + index + 1}</td>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.number || "-"}</td>
                <td>
                  {user.isOwner === 0
                    ? "User"
                    : user.isOwner === 1
                    ? "Owner"
                    : user.isOwner}
                </td>
                <td>{user.created_on.split("T")[0]}</td>
                <td>
                  {user.isBanned === 1 ? (
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleUserStatus(user.id, user.isBanned);
                      }}
                    >
                      Unban
                    </Button>
                  ) : (
                    <Button
                      color="danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleUserStatus(user.id, user.isBanned);
                      }}
                    >
                      Ban
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Pagination controls */}
        <div className="paginationBox">
          <Button
            onClick={prevPage}
            className="paginationBtn"
            disabled={currentPage === 0}
          >
            Prev
          </Button>
          <span>{currentPage + 1}</span>
          <Button
            onClick={nextPage}
            className="paginationBtn"
            disabled={currentPage === totalPage - 1}
          >
            Next
          </Button>
        </div>
      </CardBody>

      {/* Modal to display user details */}
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>User Details</ModalHeader>
        <ModalBody>
          {selectedUser && (
            <div>
              <p>User ID: {selectedUser.id}</p>
              <p>Name: {selectedUser.name}</p>
              <p>Number: {selectedUser.number}</p>
              <p>Email: {selectedUser.email || "-"}</p>
              <p>Contacted Properties:</p>
              <ul>
                {selectedUser.contacted_property &&
                  selectedUser.contacted_property.map((propertyId) => (
                    <li key={propertyId}>
                      <Link to={`/particular-property/${propertyId}`}>
                        {propertyId}
                      </Link>
                    </li>
                  ))}
              </ul>
              {/* Add more user details here */}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default UserDetails;
