// remaining to remove additional costs data

import React from "react";
import "../ListProperty.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
const Step2 = ({
  propertyDetails, // Rename propertyDetails to avoid confusion with local state
  updatePropertyDetails,
  currentStep,
  setCurrentStep,
  handleInputChange,
  handleSharingRentChange,
  setPropertyDetails
}) => {
  // Initialize propertyDetails and other state variables

  const [errors, setErrors] = useState({
    name: "",
    error: "",
  });
  const [additionalCosts, setAdditionalCosts] = useState({
    description1: "",
    cost1: "",
  });

  const furnishedameity = (value, field) => {
    onChange(value, "furnishedType");

    console.log(value, field);

    let updatedSelections;
    const newDetails = { ...propertyDetails, [field]: value };
    updatePropertyDetails(newDetails);
    if (value === "fully-furnished") {
      updatePropertyDetails({
        ...propertyDetails,
        amenities: fullyfurnished,
      });
    } else if (value === "semi-furnished") {
      updatePropertyDetails({
        ...propertyDetails,
        amenities: semifurnished,
      });
    } else if (value === "unfurnished") {
      updatePropertyDetails({
        ...propertyDetails,
        amenities: unfurnished,
      });
    }

    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };

  const onChange = (value, field) => {
    console.log(value, field);

    const newDetails = { ...propertyDetails, [field]: value };
    updatePropertyDetails(newDetails);

    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };
  const [preferences, setPreferences] = useState([]);
  const [occupancyFilter, setOccupancyFilter] = useState([
    {
      value: "Vegetarian",
      label: "Vegetarian",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-leaves-1374829.svg",
    },
    {
      value: "Night Owl",
      label: "Night Owl",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-night-time-5876451.svg",
    },
    {
      value: "Early Riser",
      label: "Early Riser",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-sun-6612922.svg",
    },
    {
      value: "Foodie",
      label: "Foodie",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pizza-2340181.svg",
    },
    {
      value: "Social Butterfly",
      label: "Social Butterfly",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-networking-138311.svg",
    },
    {
      value: "Pet Lover",
      label: "Pet Lover",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pet-lover-3347039.svg",
    },
    {
      value: "Health Conscious",
      label: "Health Conscious",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-wellness-4049800.svg",
    },
    {
      value: "Adventure Seeker",
      label: "Adventure Seeker",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-trip-1650697.svg",
    },
    {
      value: "Alcohol Enthusiast",
      label: "Alcohol Enthusiast",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-beer-6403759.svg",
    },
    {
      value: "Smoking",
      label: "Smoking",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-smoking-1473219.svg",
    },
  ]);
  const handleprefrenceChange = (value) => {
    const newOccupancyFilter = [...occupancyFilter];
    if (occupancyFilter[value].iselected === "true") {
      newOccupancyFilter[value].iselected = "false";
      setOccupancyFilter(newOccupancyFilter);
    } else {
      newOccupancyFilter[value].iselected = "true";
      setOccupancyFilter(newOccupancyFilter);
    }
    let data = occupancyFilter
      .filter((property) => property.iselected == "true")
      .map((item) => item.value);

    const newDetails = { ...propertyDetails, ["preference"]: data };
    updatePropertyDetails(newDetails);
    // applyFilters();
  };
  console.log(occupancyFilter);

  const onSharingChange = (value, field) => {
    const updatedSharing = propertyDetails?.Sharing?.includes(value)
      ? propertyDetails.Sharing.filter((item) => item !== value)
      : [...(propertyDetails.Sharing || []), value];

    const newDetails = { ...propertyDetails, [field]: updatedSharing };
    updatePropertyDetails(newDetails);

    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };

  const toggleCheckbox = (value) => {
    const isSelected = propertyDetails?.amenities?.includes(value);
    let updatedSelections;
    if (isSelected) {
      updatedSelections = propertyDetails.amenities.filter(
        (item) => item !== value
      );
    } else {
      updatedSelections = [...propertyDetails.amenities, value];
    }

    const newDetails = { ...propertyDetails, amenities: updatedSelections };
    updatePropertyDetails(newDetails);
    if (propertyDetails.amenities.length >= 1 ) {
      setErrors({
        name: "",
        error: "",
      });
    } else {
      setErrors({
        name: "amenities",
        error: "Please select at least 2 amenities.",
      });
    }
  };

  const initialSharing =
    propertyDetails && propertyDetails.Sharing ? propertyDetails.Sharing : [];
  const toggleSharingCheckbox = (value) => {
    if (!propertyDetails || !propertyDetails.Sharing) {
      // If propertyDetails or Sharing is undefined, return early
      return;
    }

    const isSelected = propertyDetails?.Sharing?.includes(value);
    let updatedSharing;
    if (isSelected) {
      updatedSharing = propertyDetails.Sharing.filter((item) => item !== value);
    } else {
      updatedSharing = [...propertyDetails.Sharing, value];
    }

    const newDetails = { ...propertyDetails, Sharing: updatedSharing };
    updatePropertyDetails(newDetails);
  };

  const bedrooms = [
    { value: "1rK", label: "1 RK" },
    { value: "1 BHK", label: "1 BHK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "5 BHK", label: "5 BHK" },
  ];
  const CurrentlyLiving = [
    { value: "1", label: 1 },
    { value: "2", label: 2 },
    { value: "3", label: 3 },
    { value: "4", label: 4 },
    { value: "5", label: 5 },
  ];

  let sharings = [
    { value: "1", label: "Single Sharing",name: "singleSharing" },
    { value: "2", label: "Double Sharing",name: "doubleSharing" },
    { value: "3", label: "Triple Sharing",name: "tripleSharing" },
  ];

  if (propertyDetails.type === "sharing flat") {
    sharings = [
      { value: "1", label: "Single Sharing",name: "singleSharing" },
      { value: "2", label: "Double Sharing",name: "doubleSharing" },
      { value: "3", label: "Triple Sharing",name: "tripleSharing" },
    ];
  } else if (propertyDetails.type === "pg") {
    sharings = [
      { value: "1", label: "Single Rent" },
      { value: "2", label: "Double Rent" },
      { value: "3", label: "Triple Rent" },
    ];
  }
  const memberedAllowed = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "both", label: "Both" },
  ];
  const yesno = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const radioButtonSubtype = [
    { value: "Flat", label: "Flat" },
    { value: "Studio Apartment", label: "Studio Apartment" },
    { value: "Independent House/Villa", label: "Independent House/Villa" },
    { value: "Rawhouse", label: "Rawhouse" },
  ];
  const preferenceTypes = [
    {
      value: "Vegetarian",
      label: "Vegetarian",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-leaves-1374829.svg",
    },
    {
      value: "Night Owl",
      label: "Night Owl",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-night-time-5876451.svg",
    },
    {
      value: "Early Riser",
      label: "Early Riser",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-sun-6612922.svg",
    },
    {
      value: "Foodie",
      label: "Foodie",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pizza-2340181.svg",
    },
    {
      value: "Social Butterfly",
      label: "Social Butterfly",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-networking-138311.svg",
    },
    {
      value: "Pet Lover",
      label: "Pet Lover",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pet-lover-3347039.svg",
    },
    {
      value: "Health Conscious",
      label: "Health Conscious",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-wellness-4049800.svg",
    },
    {
      value: "Adventure Seeker",
      label: "Adventure Seeker",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-trip-1650697.svg",
    },
    {
      value: "Alcohol Enthusiast",
      label: "Alcohol Enthusiast",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-beer-6403759.svg",
    },
    {
      value: "Smoking",
      label: "Smoking",
      iselected: "false",
      image:
        "https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-smoking-1473219.svg",
    },
  ];

  const Sharing = [
    { value: "1", label: "Single Sharing" },
    { value: "2", label: "Double Sharing" },
    { value: "3", label: "Triple Sharing" },
  ];
  const amenities = [
    { value: "electricity", label: "Electricity" },
    { value: "fridge", label: "Fridge" },
    { value: "kitchen", label: "Kitchen" },
    { value: "laundry", label: "Laundry" },
    { value: "AC", label: "AC" },
    { value: "balcony", label: "Balcony" },
    { value: "CCTV", label: "CCTV" },
    { value: "fan", label: "Fan" },
    { value: "stove", label: "Stove" },
    { value: "gyser", label: "Gyser" },
    { value: "lift", label: "Lift" },
    { value: "water", label: "Water" },
    { value: "wifi", label: "Wifi" },
    { value: "desk", label: "Desk" },
    { value: "parking", label: "Parking" },
    { value: "guard", label: "Guard" },
    { value: "sofa", label: "Sofa" },
    { value: "tv", label: "Tv" },
    { value: "wardrobe", label: "Wardrobe" },
    { value: "machine", label: "Machine" },
  ];

  const furnishedType = [
    { value: "fully-furnished", label: "Fully-furnished" },
    { value: "semi-furnished", label: "Semi-furnished" },
    { value: "unfurnished", label: "Un-furnished" },
  ];
  const fullyfurnished = [
    "electricity",
    "tv",
    "balcony",
    "parking", // Replacing "2-wheeler-parking"
    "wifi", // Replacing "free-wifi"
    "kitchen", // Replacing "cooking"
    "house-keeping", // Assuming this might be "laundry"
    "laundry",
    "fridge",
    "water", // Replacing "ro-water" and "24*7-water"
    "AC", // Replacing "air-conditioner"
    "breakfast", // Assuming not present in API
    "gyser",
    "lunch", // Assuming not present in API
    "guard", // Replacing "security"
    "microwave", // Assuming not present in API
    "fan", // Replacing "fans"
    "wardrobe",
    "lift",
    "CCTV",
    "sofa",
    "stove",
    "machine",
    "desk",
  ];
  const semifurnished = ["electricity", "water", "fan", "wardrobe", "kitchen"];

  const unfurnished = ["fan", "electricity", "water", "guard"];
  const validateForm = () => {
    const newErrors = {};
    if (!propertyDetails.subtype) {
      newErrors.name = "subtype";
      newErrors.error = "Please select a property type.";
    }
    if (
      !propertyDetails.preference &&
      (propertyDetails.propertyType === "PG" ||
        propertyDetails.propertyType === "sharing flat")
    ) {
      newErrors.name = "preferenceTypes";
      newErrors.error = "Please select at least 3 Prefrences.";
    }
    if (propertyDetails.amenities.length < 2 ) {
      newErrors.name = "amenities";
      newErrors.error = "Please select at least 2 amenities.";
    }

    if (!propertyDetails.furnishedType) {
      newErrors.name = "furnishedType";
      newErrors.error = "Please select the furnished type.";
    }
    if (propertyDetails.nonVegAllowed === null) {
      newErrors.name = "nonVegAllowed";
      newErrors.error = "Please select whether non-veg is allowed.";
    }

    if (propertyDetails.type === "pg") {
      if (!propertyDetails.amenities) {
        newErrors.name = "sharing";
        newErrors.error = "Please select the sharing option.";
      }

      // if (propertyDetails.withoutFood === null) {
      //   newErrors.name = "withoutFood";
      //   newErrors.error =
      //     "Please select whether without food option is available.";
      // }
    }


    
    if (!propertyDetails.memberedAllowed) {
      newErrors.name = "memberedAllowed";
      newErrors.error = "Please select whether members are allowed.";
    }

    if (!propertyDetails.bedroom) {
      newErrors.name = "bedroom";
      newErrors.error = "Please select the number of bedrooms.";
    }
    if (!propertyDetails.subtype) {
      newErrors.name = "subtype";
      newErrors.error = "Please select type of property.";
    }
console.log(propertyDetails.preference);
    if (propertyDetails.type === "sharing flat") {
      if (!propertyDetails.subtype) {
        newErrors.name = "subtype";
        newErrors.error = "Please select a property type.";
      }
      if (propertyDetails?.preference?.length < 3) {
        newErrors.name = "preferenceTypes";
        newErrors.error = "Please select at least 3 Prefrences.";
      }
      
      if (!propertyDetails.sharingType?.singleRent && !propertyDetails.sharingType?.doubleRent && !propertyDetails.sharingType?.tripleRent) {
        newErrors.name = "rentEachHead";
        newErrors.error = "Please enter the  Rent each head amount.";
      }
      if (!propertyDetails.totalFlatRent) {
        newErrors.name = "totalFlatRent";
        newErrors.error = "Please enter the Total rent amount.";
      }
    }
  
    if (propertyDetails.type === "pg") {

      // if (!propertyDetails.rentEachHead) {
      //   newErrors.name = "rentEachHead";
      //   newErrors.error = "Please enter the  Rent each head amount.";
      // }
     
    }
    if (propertyDetails.type === "private flat"){
   
    if (!propertyDetails.totalFlatRent) {
      newErrors.name = "rent";
      newErrors.error = "Please enter the rent amount.";
    }
    if (!propertyDetails.deposit) {
      newErrors.name = "deposit";
      newErrors.error = "Please enter the deposit amount.";
    }
  
    }



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  console.log(propertyDetails.sharingType?.singleRent);
  const handleNextClick = () => {
    const isValid = validateForm();
    console.log(errors);
    if (isValid) {
      setCurrentStep(currentStep + 1);
    }
  };
  const navigate = useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //merge step data

  const [isOpen, setIsOpen] = useState(false);
  const [addInst, setAddInst] = useState(false);
  const toggleSection = () => {
    setIsOpen(!isOpen);
  };
  const setDate = (e) => {
    const newAvailableFrom = e.target.value;
    const newDetails = { ...propertyDetails, availableFrom: newAvailableFrom };
    updatePropertyDetails(newDetails);
  };
  const handleCheckboxChange = () => {
    const newDetails = {
      ...propertyDetails,
      isNegotiable: !propertyDetails.isNegotiable,
    };

    updatePropertyDetails(newDetails);
  };

  const handleFieldChange = (field, value) => {
    setAdditionalCosts((prevCosts) => ({
      ...prevCosts,
      [field]: value,
    }));
  };

  const handleAddMore = () => {
    const nextIndex = Object.keys(additionalCosts).length / 2 + 1;
    if (nextIndex <= 3) {
      setAdditionalCosts((prevCosts) => ({
        ...prevCosts,
        [`description${nextIndex}`]: "",
        [`cost${nextIndex}`]: "",
      }));
      const newDetails = {
        ...propertyDetails,
        additionalCosts: additionalCosts,
      };
      updatePropertyDetails(newDetails);
    }
  };

  const maxLengthMap = {
    singleSharingRent: 6,
    doubleSharingRent: 6,
    tripleSharingRent: 6,
  };

  return (
    <div className="container row" id="step2">
      <div className="col-md-4 col-lg-3">
        <div className="step-navigation" id="progressBar">
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Basic Details
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 1
            </small>
          </div>
          <div className={"step active completed"}>
            <span className="step-number active"></span>
            Property Profile
          </div>
          <div className="d-flex">
            <div className="connector"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 2
            </small>
          </div>
          {/* <div className={"step"}>
            <span className="step-number"></span>
            Proprty Pricing
          </div>
          <div className="d-flex">
            <div className="connector"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 3
            </small>
          </div> */}
          <div className="step">
            <span className="step-number"></span>
            Photos
          </div>
        </div>
        <div className="mt-3" id="needHelp">
          <h2>Need Help ?</h2>
          <h6 style={{ opacity: "0.8" }}>Contact us Now</h6>
          <span>
            <FontAwesomeIcon icon={faPhone} /> +91-9316066832{" "}
          </span>
        </div>
      </div>
      <div className="col-md-6 col-lg-5" id="middle">
        <div
          role="button"
          id="backDiv"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          {"  "} Back
        </div>
        <h4 className="MiddleHeading mt-4">Tell us about your property</h4>
        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Type of property :
          </Label>
          <div className="radio-groups">
            {radioButtonSubtype.map((button, index) => (
              <div
                key={button.value}
                className={`radio-button rounded-pill ${
                  propertyDetails.subtype === button.value ? "selected" : ""
                }`}
                onClick={() => onChange(button.value, "subtype")}
              >
                {button.label}
              </div>
            ))}
          </div>
          {errors.name === "subtype" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Bedrooms :
          </Label>
          <div className="radio-groups">
            {bedrooms.map((button) => (
              <div
                key={button.value}
                className={`radio-button rounded-pill  ${
                  propertyDetails.bedroom === button.value ? "selected" : ""
                }`}
                onClick={() => onChange(button.value, "bedroom")}
              >
                <Label id="labelforMobileView" className="radio-label">
                  <input
                    type="radio"
                    value={button.value}
                    checked={propertyDetails.bedroom === button.value}
                    onChange={() => onChange(button.value, "bedroom")}
                  />
                  {button.label}
                </Label>
              </div>
            ))}
          </div>
          {errors.name === "bedroom" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Membered Allowed:
          </Label>
          <div className="radio-groups">
            {memberedAllowed.map((button) => (
              <div
                key={button.value}
                className={`radio-button rounded-pill  ${
                  propertyDetails.memberedAllowed === button.value
                    ? "selected"
                    : ""
                }`}
                onClick={() => onChange(button.value, "memberedAllowed")}
              >
                <Label id="labelforMobileView" className="radio-label">
                  <input
                    type="radio"
                    value={button.value}
                    checked={propertyDetails.memberedAllowed === button.value}
                    onChange={() => onChange(button.value, "memberedAllowed")}
                  />
                  {button.label}
                </Label>
              </div>
            ))}
          </div>
          {errors.name === "memberedAllowed" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        {["sharing flat", "pg"]?.includes(propertyDetails.type) && (
          <FormGroup>
            <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
              Per Sharing:
            </Label>
            <div
              className="parent-sharing-box"
              style={{ display: "flex", gap: "20px", position: "relative" }}
            >
              <div className="Sharing-box">
                <FormGroup>
                  <div
                    className="radio-groups"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* {sharings.map((sharingOption) => ( */}
                    <div
                        key={1}
                        className={`radio-button rounded-pill ${
                          propertyDetails?.sharingType?.singleSharing =="single" || false
                            ? "selected"
                            : ""
                        }`}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Label className="radio-label">
                          <input
                            style={{ display: "none" }}
                            type="checkbox"
                            name={"singleRent"}
                            value={ propertyDetails?.sharingType?.singleSharing}
                            checked={
                              propertyDetails?.sharingType?.singleSharing =="single" || false
                            }
                            onClick={(e) =>{
                              if (propertyDetails?.sharingType?.singleSharing == "") {
                                console.log("DAs");
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                    ...prevState.sharingType,
                                    singleSharing: "single"
                                  }
                                }))
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                      ...prevState.sharingType,
                                      singleRent: "0"
                                  }
                              }))
                              }
                              else{
                              console.log("D");
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    singleSharing: ""
                                }
                            }))
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    singleRent: ""
                                }
                            }))
                            }}
                            }
                          />
                          {"Single Sharing"}
                        </Label>
                      </div>
                      <div
                        key={2}
                        className={`radio-button rounded-pill ${
                          propertyDetails?.sharingType?.doubleSharing!=="double" || false
                            ? "selected"
                            : ""
                        }`}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Label className="radio-label">
                          <input
                            style={{ display: "none" }}
                            type="checkbox"
                            name={"doubleRent"}
                          
                            value={ propertyDetails?.sharingType?.doubleSharing}
                            checked={
                              propertyDetails?.sharingType?.doubleSharing!=="double" || false
                            }
                            onClick={(e) =>{
                              if (propertyDetails?.sharingType?.doubleSharing == "") {
                                console.log("DAs");
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                    ...prevState.sharingType,
                                    doubleSharing: "double"
                                  }
                                }))
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                      ...prevState.sharingType,
                                      doubleRent: "0"
                                  }
                              }))
                              }
                              else{
                              console.log("D");
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    doubleSharing: ""
                                }
                            }))
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    doubleRent: ""
                                }
                            }))
                            }}}
                          />
                          {"Double Sharing"}
                        </Label>
                      </div>
                      <div
                        key={3}
                        className={`radio-button rounded-pill ${
                          propertyDetails?.sharingType?.tripleSharing!=="triple" || false
                            ? "selected"
                            : ""
                        }`}
                       
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Label className="radio-label">
                          <input
                            style={{ display: "none" }}
                            type="checkbox"
                            name={"tripleRent"}
                            // value={sharingOption.value}
                           
                            checked={
                              propertyDetails?.sharingType?.tripleSharing!== "triple" || false
                            }
                            onClick={(e) =>{
                              if (propertyDetails?.sharingType?.tripleSharing == "") {
                                console.log("DAs");
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                    ...prevState.sharingType,
                                    tripleSharing: "triple"
                                  }
                                }))
                                setPropertyDetails(prevState => ({
                                  ...prevState,
                                  sharingType: {
                                      ...prevState.sharingType,
                                      tripleRent: "0"
                                  }
                              }))
                              }
                              else{
                              console.log("D");
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    tripleSharing: ""
                                }
                            }))
                              setPropertyDetails(prevState => ({
                                ...prevState,
                                sharingType: {
                                    ...prevState.sharingType,
                                    tripleRent: ""
                                }
                            }))
                            }}}
                          />
                          {"Triple Sharing"}
                        </Label>
                      </div>
                    {/* ))} */}
                  </div>
                  {errors.name === "sharing" && (
                    <div className="error-message text-danger">
                      {errors.error}
                    </div>
                  )}
                </FormGroup>
              </div>

              <div
                className="sharing-rent-inputs"
                style={{ position: "relative" }}
              >
                {propertyDetails?.sharingType?.singleSharing == "single" && (
                  <FormGroup style={{ display: "flex" }}>
                    <Input
                      type="number"
                      className="inputStep3"
                      name="singleSharingRent"
                      placeholder="Single sharing rent"
                      maxLength={6} // Maximum length set to 6
                      value={propertyDetails.sharingType?.singleRent || ""}
                      onChange={(e) =>
                        setPropertyDetails(prevState => ({
                          ...prevState,
                          sharingType: {
                              ...prevState.sharingType,
                              singleRent: e.target.value
                          }
                      }))}
                      style={{
                        height: "30px",
                        fontSize: "14px",
                        width: "200px",
                        position: "absolute",
                      }}
                    />
                  </FormGroup>
                )}
                {propertyDetails?.sharingType?.doubleSharing!=="double" && (
                  <FormGroup style={{ display: "flex" }}>
                    <Input
                      type="number"
                      className="inputStep3"
                      name="doubleSharingRent"
                      placeholder="Double sharing rent"
                      maxLength={6}
                      value={propertyDetails.sharingType?.doubleRent || ""}
                      onChange={(e) =>
                        setPropertyDetails(prevState => ({
                          ...prevState,
                          sharingType: {
                              ...prevState.sharingType,
                              doubleRent: e.target.value
                          }
                      }))}
                    
                      style={{
                        height: "30px",
                        fontSize: "14px",
                        width: "200px",
                        position: "absolute",
                        top: "34%",
                      }}
                    />
                    {errors.name === "doubleSharingRent" && (
                      <div className="error-message text-danger">
                        {errors.error}
                      </div>
                    )}
                  </FormGroup>
                )}
                {propertyDetails?.sharingType?.tripleSharing!=="triple" && (
                  <FormGroup style={{ display: "flex" }}>
                    <Input
                      type="number"
                      className="inputStep3"
                      name="tripleSharingRent"
                      placeholder="Triple sharing rent"
                      maxLength={6}
                     
                      value={propertyDetails.sharingType?.tripleRent || ""}
                      onChange={(e) =>
                        setPropertyDetails(prevState => ({
                          ...prevState,
                          sharingType: {
                              ...prevState.sharingType,
                              tripleRent: e.target.value
                          }
                      }))}
                      style={{
                        height: "30px",
                        fontSize: "14px",
                        width: "200px",
                        position: "absolute",
                        top: "65%",
                      }}
                    />
                    {errors.name === "tripleSharingRent" && (
                      <div className="error-message text-danger">
                        {errors.error}
                      </div>
                    )}
                  </FormGroup>
                )}
              </div>
            </div>
            {errors.name === "rentEachHead" && (
              <div className="error-message text-danger">{errors.error}</div>
            )}
          </FormGroup>
        )}

        <FormGroup>
          <Label
            id="labelforMobileView"
            style={{ fontWeight: "500", display: "block" }}
          >
            Non-Veg Allowed:
          </Label>
          <div className="radio-groups">
            {yesno.map((button) => (
              <div
                key={button.value}
                className={`radio-button rounded-pill  ${
                  propertyDetails.nonVegAllowed === button.value
                    ? "selected"
                    : ""
                }`}
                onClick={() => onChange(button.value, "nonVegAllowed")}
              >
                <Label id="labelforMobileView" className="radio-label">
                  <input
                    type="radio"
                    value={button.value}
                    checked={propertyDetails.nonVegAllowed === button.value}
                    onChange={() => onChange(button.value, "nonVegAllowed")}
                  />
                  {button.label}
                </Label>
              </div>
            ))}
          </div>
          {errors.name === "nonVegAllowed" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Furnished Type:
          </Label>
          <div className="radio-groups">
            {furnishedType.map((button) => (
              <div
                key={button.value}
                className={`radio-button rounded-pill  ${
                  propertyDetails.furnishedType === button.value
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  furnishedameity(button.value, "furnishedType");
                }}
              >
                <Label id="labelforMobileView" className="radio-label">
                  <input
                    type="radio"
                    value={button.value}
                    checked={propertyDetails.furnishedType === button.value}
                    onChange={() => onChange(button.value, "furnishedType")}
                  />
                  {button.label}
                </Label>
              </div>
            ))}
          </div>
          {errors.name === "furnishedType" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Select Amenities available:
          </Label>
          <div className="radio-groups">
            <div className="checkbox-group">
              {amenities.map((button) => (
                <div
                  key={button.value}
                  className={`checkbox-button rounded-pill  ${
                    propertyDetails?.amenities?.includes(button.value)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => toggleCheckbox(button.value)}
                >
                  <Label
                    id="labelforMobileView"
                    className="checkbox-label"
                    htmlFor={button.value}
                  >
                    <input
                      type="checkbox"
                      value={button.value}
                      checked={propertyDetails?.amenities?.includes(button.value)}
                      onChange={() => toggleCheckbox(button.value)}
                    />
                    {button.label}
                  </Label>
                </div>
              ))}
            </div>
          </div>
          {errors.name === "amenities" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        {/* <h4 className="MiddleHeading my-4">Add Pricing and details...</h4> */}
        <FormGroup className="d-flex flex-wrap align-items-center">
          <label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Available from :{" "}
          </label>
          <Input
            type="date"
            style={{ width: "50%", marginLeft: "0.8rem" }}
            className="inputStep3"
            name="availableFrom"
            value={propertyDetails.availableFrom}
            onChange={setDate}
          />
          {errors.name === "available" && (
            <div className="error-message text-danger">{errors.error}</div>
          )}
        </FormGroup>
        {propertyDetails.type !== "sharing flat" && propertyDetails.type !== "pg" && (
          <div>
            <div className="d-flex flex-wrap justify-content-between">
              <FormGroup>
                <label id="labelforMobileView">Total Rent : </label>
                <Input
                  type="number"
                  className="inputStep3"
                  name="totalFlatRent"
                  maxLength={6}
                  value={propertyDetails.totalFlatRent}
                  onChange={handleInputChange}
                />
                {errors.name === "rent" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Deposit (in Months):
                </label>
                <Input
                  type="number"
                  className="inputStep3"
                  name="deposit"
                  placeholder="Ex: 1 Rent"
                  value={propertyDetails.deposit}
                  onChange={handleInputChange}
                />
                {errors.name === "deposit" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
        )} 
        <FormGroup check>
          <label check className="mb-3">
            <Input
              type="checkbox"
              name="isNegotiable"
              checked={propertyDetails.isNegotiable}
              onChange={handleCheckboxChange}
            />
            Rent Negotiable
          </label>
        </FormGroup>

        {propertyDetails.type === "sharing flat" && (
          <>
            <div className="d-flex   flex-wrap justify-content-between">
              <FormGroup>
                <label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Total Flat Rent :{" "}
                </label>
                <Input
                  type="number"
                  className="inputStep3"
                  name="totalFlatRent"
                  placeholder="Total Flat Rent"
                  value={propertyDetails.totalFlatRent}
                  onChange={handleInputChange}
                />
                {errors.name === "totalFlatRent" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Deposit (in Months):
                </label>
                <Input
                  type="number"
                  className="inputStep3"
                  name="deposit"
                  placeholder="Ex: 1 Rent"
                  style={{ width: "42%" }}
                  value={propertyDetails.deposit}
                  onChange={handleInputChange}
                />
                {errors.name === "deposit" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
            </div>
          </>
        )}
        {/* <span className="text-primary" role="button" onClick={toggleSection}>
          {isOpen ? (
            <>
              {" "}
              - Additional Cost <span className="grey">(optional)</span>{" "}
            </>
          ) : (
            <>
              {" "}
              + Additional Cost <span className="grey">(optional)</span>{" "}
            </>
          )}
        </span> */}
        {isOpen && (
          <div className=" pb-3">
            {Object.keys(additionalCosts).map((key, index) => {
              if (index % 2 === 0) {
                const descriptionKey = key;
                const costKey = `cost${index / 2 + 1}`;
                return (
                  <div className="d-flex" key={index}>
                    <Input
                      type="text"
                      placeholder="Description"
                      value={additionalCosts[descriptionKey]}
                      onChange={(e) =>
                        handleFieldChange(descriptionKey, e.target.value)
                      }
                    />
                    <Input
                      type="text"
                      style={{ width: "50%", marginLeft: "0.8rem" }}
                      placeholder="Cost"
                      value={additionalCosts[costKey]}
                      onChange={(e) =>
                        handleFieldChange(costKey, e.target.value)
                      }
                    />
                  </div>
                );
              }
              return null;
            })}
            {Object.keys(additionalCosts).length / 2 < 3 && (
              <div
                role="button"
                className="mt-1 fs-7 grey"
                onClick={handleAddMore}
              >
                + Add More
              </div>
            )}
          </div>
        )}
        <div className="mt-3">
          {/* <span
            className="text-primary"
            role="button"
            onClick={() => {
              setAddInst(!addInst);
            }}
          >
            {addInst ? (
              <>
                {" "}
                - Add instructions <span className="grey">(optional)</span>{" "}
              </>
            ) : (
              <>
                {" "}
                + Add instructions <span className="grey">(optional)</span>{" "}
              </>
            )}
          </span> */}
          {/* {addInst && ( */}
          <FormGroup className=" py-3">
            <label id="labelforMobileView" style={{ fontWeight: "500" }}>
              Add instructions or any rules or regulations:
            </label>
            <Input
              type="text"
              className="inputStep3"
              name="instructions"
              placeholder="Write Here..."
              value={propertyDetails.instructions}
              onChange={handleInputChange}
            />
          </FormGroup>
          {/* )} */}
        </div>
        {propertyDetails.type === "sharing flat" && (
          <>
            <FormGroup>
              <label id="labelforMobileView" style={{ fontWeight: "500" }}>
                Washroom Attached:
              </label>
              <div className="radio-groups">
                {yesno.map((button) => (
                  <div
                    key={button.value}
                    className={`radio-button rounded-pill  ${
                      propertyDetails.washroomAttached === button.value
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => onChange(button.value, "washroomAttached")}
                  >
                    <label id="labelforMobileView" className="radio-label">
                      <input
                        type="radio"
                        value={button.value}
                        checked={propertyDetails.nonVegAllowed === button.value}
                        onChange={() => onChange(button.value, "nonVegAllowed")}
                      />
                      {button.label}
                    </label>
                  </div>
                ))}
              </div>
              {errors.name === "nonVegAllowed" && (
                <div className="error-message text-danger">{errors.error}</div>
              )}
            </FormGroup>
            <FormGroup>
              <label style={{ fontWeight: "500" }}>preferenceTypes:</label>
              <div className="radio-groups">
                {preferenceTypes.map((button, index) => (
                  <div
                    key={button.value}
                    className={`z-20 radio-button rounded-pill  ${
                      propertyDetails.preference &&
                      propertyDetails?.preference?.includes(button.value)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleprefrenceChange(index, "preferenceTypes")
                    }
                  >
                    <label className="radio-label">
                      <div
                        type="radio"
                        value={button.value}
                        checked={propertyDetails?.preference?.includes(
                          button.value
                        )}
                        onChange={() =>
                          handleprefrenceChange(index, "preferenceTypes")
                        }
                      />
                      {button.label}
                    </label>
                  </div>
                ))}
              </div>
              {errors.name === "preferenceTypes" && (
                <div className="error-message text-danger">{errors.error}</div>
              )}
            </FormGroup>
          </>
        )}

        <FormGroup>
          <label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Notice period
          </label>
          <Input
            type="select"
            className="inputStep3"
            name="noticePeriond"
            placeholder="Ex: 1 Month"
            style={{ width: "42%" }}
            value={propertyDetails.noticePeriond}
            onChange={handleInputChange}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </Input>
        </FormGroup>
        <FormGroup className="py-3">
          <label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Description:
          </label>
          <Input
            type="text"
            className="inputStep3"
            name="description"
            placeholder="Write Here..."
            value={propertyDetails.description}
            onChange={handleInputChange}
          />
        </FormGroup>

        <div className="ButtonBox mt-4">
          <Button
            className="previousBtn rounded margin-demo"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </Button>
          <Button
            className="continueBtn rounded margin-demo1"
            onClick={handleNextClick}
          >
            Continue
          </Button>
        </div>
      </div>
      {/* /**  <div className="col-lg-3" id="contactSection">
        <div className="d-flex flex-column justify-items-start list-points">
          <p className="fw-normal col-md-12 my-0 mb-3" id="">
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                fill="#00B090"
              />
            </svg>{" "}
            Create Your Property Listing
          </p>
          <p className="fw-normal col-md-12 my-0 mb-3" id="">
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                fill="#00B090"
              />
            </svg>{" "}
            Get Direct Tenant Inquiries
          </p>
          <p className="fw-normal col-md-12 my-0 mb-3" id="">
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                fill="#00B090"
              />
            </svg>{" "}
            Secure Property Details{" "}
          </p>
          <p className="fw-normal col-md-12 my-0 mb-3" id="">
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                fill="#00B090"
              />
            </svg>{" "}
            Quick and Easy Access{" "}
          </p>
          <p className="fw-normal col-md-12 my-0 mb-3" id="">
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                fill="#00B090"
              />
            </svg>{" "}
            No Additional Charges/Brokerage{" "}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Step2;
