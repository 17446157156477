import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";

const adminId = Cookies.get("id");

// AdminDetailsModal Component
const AdminDetailsModal = ({ isOpen, toggle, adminDetails }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Admin Details</ModalHeader>
      <ModalBody>
        <p>ID: {adminDetails.id}</p>
        <p>Name: {adminDetails.name}</p>
        {/* Add other admin details as needed */}
        <Button onClick={toggle}>Close</Button>
      </ModalBody>
    </Modal>
  );
};

const AllAdminDetails = () => {
const [superpassword, setSuperpassword] = useState("1");
  const [admins, setAdmins] = useState([]);
  const [adminDetails, setAdminDetails] = useState({});
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAdminDetailsModal, setShowAdminDetailsModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [selectedAdminForDeletion, setSelectedAdminForDeletion] =
    useState(null); // New state variable

  const Navigate = useNavigate();

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
  ];
  const isSuperAdmin = useCallback((adminId) => {
    return superAdminIds.includes(adminId);
  }, [superAdminIds]); // Include superAdminIds as a dependency
  
  useEffect(() => {
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId, isSuperAdmin]); // Include dependencies adminId and isSuperAdmin
  

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/all-admin/vjkfbru`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Sending an empty body
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAdmins(data);
      })
      // .catch((err) => console.log(err));
  }, []);

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  const toggleAdminDetailsModal = () => {
    setShowAdminDetailsModal(!showAdminDetailsModal);
  };

  const toggleDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
  };

  const toggleChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const handleAdminClick = (adminId) => {
    setSelectedAdminId(adminId);

    // Find the admin with the matching adminId in the existing admins data
    const selectedAdmin = admins.find((admin) => admin.id === adminId);

    if (selectedAdmin) {
      // Set the admin details to the selected admin
      setAdminDetails(selectedAdmin);
      toggleAdminDetailsModal(); // Open the admin details modal
    }
  };

  const handleDeleteButtonClick = (adminId) => {
    setSelectedAdminForDeletion(adminId); // Set the selected admin for deletion
    toggleDeleteConfirmationModal(); // Open the delete confirmation modal
  };

  const handleDeleteConfirmation = () => {
    // Perform the delete operation here
    // You can use selectedAdminForDeletion to identify the admin to delete
    const adminIdToDelete = selectedAdminForDeletion;
    if (adminIdToDelete) {
      fetch(
        `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/delete-admin/${adminIdToDelete}`,
        {
          method: "GET",
        }
      )
        .then(() => {
          if (adminIdToDelete === localStorage.getItem("name")) {
            localStorage.removeItem("name");
            Navigate("/login");
          }
          setAdmins(admins.filter((admin) => admin.id !== adminIdToDelete));
          toggleDeleteConfirmationModal(); // Close the delete confirmation modal after successful delete
        })
        // .catch((err) => console.log(err));
    }
  };

  const handlePasswordChange = () => {
    // Perform the password change operation here
    // You can use selectedAdminId and newPassword to change the admin's password
    const adminIdToChangePassword = selectedAdminId;
    if (adminIdToChangePassword) {
      fetch(
        `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/admin-password-change/${adminIdToChangePassword}/cihsdbhuvusv`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newpassword: newPassword }), // Use "newpassword" key in the request body
        }
      )
        .then((res) => {
          if (res.ok) {
            const updatedAdmins = admins.map((admin) => {
              if (admin.id === adminIdToChangePassword) {
                return { ...admin, password: newPassword };
              }
              return admin;
            });
            setAdmins(updatedAdmins);
            toggleChangePasswordModal();
            setNewPassword("");
          } else {
            // Handle password change error
            console.error("Failed to change password");
          }
        })
        .catch((err) => {
          console.error(err);
          // Handle password change error
        });
    }
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
        <i className="bi bi-people me-2"></i>
        All Admins
      </CardTitle>
      <CardBody style={{ overflowX: "auto" }}>
        <div className="bg-white">
          <Modal isOpen={showModal}>
            <ModalHeader>
              <span>Password Verification </span>
              <Link to="/starter" style={{ "padding-left": "175px" }}>
                <Button>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </Link>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleModalSubmit}>
                <FormGroup>
                  <Label for="superpassword">Password:</Label>
                  <Input
                    type="password"
                    id="superpassword"
                    required
                    value={superpassword}
                    onChange={(e) => setSuperpassword(e.target.value)}
                  />
                </FormGroup>
                {error && <p className="text-danger">{error}</p>}
                <Button type="submit">Continue</Button>
              </Form>{" "}
            </ModalBody>
          </Modal>

          <AdminDetailsModal
            isOpen={showAdminDetailsModal}
            toggle={toggleAdminDetailsModal}
            adminDetails={adminDetails}
          />

          <Modal isOpen={showDeleteConfirmationModal}>
            <ModalHeader>Delete Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to delete this admin?
              <Button onClick={handleDeleteConfirmation}>Confirm</Button>
              <Button onClick={toggleDeleteConfirmationModal}>Cancel</Button>
            </ModalBody>
          </Modal>

          <Modal isOpen={showChangePasswordModal}>
            <ModalHeader>Change Password</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="newPassword">New Password:</Label>
                  <Input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormGroup>
                <Button onClick={handlePasswordChange}>Change Password</Button>
                <Button onClick={toggleChangePasswordModal}>Cancel</Button>
              </Form>
            </ModalBody>
          </Modal>

          {/* <h3 className="text-center">All Admins</h3> */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr.</th>
                <th>ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Delete</th>
                <th>Change Password</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin, index) => (
                <tr key={index} onClick={() => handleAdminClick(admin.id)}>
                  <th scope="row">{index + 1}</th>
                  <td>{admin.id}</td>
                  <td>{admin.name}</td>
                  <td>{admin.number}</td>
                  <td>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleDeleteButtonClick(admin.id); // Handle the delete button click
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setSelectedAdminId(admin.id);
                        toggleChangePasswordModal();
                      }}
                    >
                      Change Password
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AllAdminDetails;
