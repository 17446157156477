import "./UnverifiedProperties.css";
import { React, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import NearbyLocations from "./NearbyLocations.jsx";
import MultiRangeSlider from "multi-range-slider-react";
import "./Flat1.css";

import {
  faArrowRight,
  faArrowLeft,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import { Modal } from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "../../api/axios.js";
import ParticularFlatProperty from "./ParticularProperty.js";
import ParticularFlatSeekerProperty from "./particularFlatSeekerProperty.jsx";
const adminId = Cookies.get("id");
const AllFlatSeekers = (props) => {
  const { user } = props;
  const [superpassword, setSuperpassword] = useState("1");
  const [selectedPropertyID, setSelectedPropertyID] = useState(null);

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [expirePopupmodal, setExpirePopupModal] = useState(false);

  const [filteredProperties, setFilteredProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [propertyCount, setPropertyCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [propertyIdToDelete, setPropertyIdToDelete] = useState(null);
  const [propData, setPropData] = useState([]);

  const [all, setAll] = useState(true);
  const location = useLocation();

  const [rented, setRented] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [unverified, setUnverified] = useState(false);
  const [show, setShow] = useState(true);
  console.log(show);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleShow = (propertyID) => {
    setSelectedPropertyID(propertyID);
    const params = new URLSearchParams(location.search);
    params.set("propertyId", propertyID);
    navigate({ pathname: location.pathname, search: params.toString() });
  };

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
    "65be1ac595dac22ebe9f9b88",
  ];
  const [locationFilter, setLocationFilter] = useState("");

  const [city, setcity] = useState("");
  const [minValue, set_minValue] = useState();
  const Occupancy = [
    { value: "Single", label: "Single" },
    { value: "Double", label: "Double" },
    { value: "Triple", label: "Triple" },
  ];
  const filtertype = [
    { value: "Independent House/Villa", label: "Independent House/Villa" },
    { value: "Flat", label: "Flat" },
    { value: "Rawhouse", label: "Rawhouse" },
    { value: "Studio apartment", label: "Studio apartment" },
  ];
  const [preferencesFilter, setPreferencesFilter] = useState("");

  const Preferences = [
    { value: "Vegetarian", label: "Vegetarian" },
    { value: "Night Owl ", label: "Night Owl " },
    { value: "Early Riser", label: "Early Riser" },
    { value: "Foodie", label: "Foodie" },
    { value: "Social Butterfly", label: "Social Butterfly" },
    { value: "Alcohol Enthusiast", label: "Alcohol Enthusiast" },
    { value: "Pet Lover", label: "Pet Lover" },
    { value: "Health Conscious", label: "Health Conscious" },
    { value: "Adventure Seeker", label: "Adventure Seeker" },
    { value: "Smoking", label: "Smoking" },
  ];
  const [BedroomFilter, setBedroomFilter] = useState("");

  const Bedroom = [
    { value: "1 RK", label: "1 RK" },
    { value: "1 BHK", label: "1 BHK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "5 BHK", label: "5 BHK" },
  ];
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "", label: "Both" },
  ];
  const [type, settype] = useState("");
  const [cityFilter, setcityFilter] = useState("");
  const [occupancyFilter, setOccupancyFilter] = useState(
    Occupancy.map((occupancy) => ({ ...occupancy, isSelected: false }))
  );
  const [furnishedFilter, setFurnishedFilter] = useState("");
  const [availabilityFilter, setAvailabilityFilter] = useState("");
  const [preference, setpreference] = useState("");
  const [preferencefilter, setpreferencefilter] = useState("");
  const [area, setarea] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const Furnished = [
    { value: "fully-furnished", label: "Furnished" },
    { value: "unfurnished", label: "Unfurnished" },
    { value: "semi-furnished", label: "Semi-furnished" },
  ];
  const [shiftingDate, setShiftingDate] = useState("");
  const [inputValue, setInputValue] = useState('');


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchClick = () => {
    setSearchQuery(inputValue);
    // applyFilters(); 
    // Call applyFilters to perform the search with the current query
  };

  const handleInput = (el) => {
    
    set_minValue(el);
  };
  const handleGenderChange = (value) => {
    setGenderFilter(value);
  };
  const handleFurnishedChange = (value) => {
    if (value === furnishedFilter) {
      setFurnishedFilter("");
    } else {
      setFurnishedFilter(value);
    }
  };

  const handleBedroomChange = (value) => {
    if (value === BedroomFilter) {
      setBedroomFilter("");
    } else {
      setBedroomFilter(value);
    }
  };
  const handleLocationChange = (value) => {
    setLocationFilter(value);
  };
  const handleareaChange = (value) => {
    setarea([...area, value]);
  };
  const removearea = (items) => {
    const data = area.filter((item) => items != item);
    setarea(data);
  };
  const handleOccupancyChange = (index) => {
    setOccupancyFilter((prevFilter) => {
      const newFilter = [...prevFilter];
      newFilter[index].isSelected = !newFilter[index].isSelected;
      return newFilter;
    });
  };

  const handletypeChange = (value) => {
    if (value === type) {
      settype("");
    } else {
      settype(value);
    }
  };
  const applyFilters = async (currentpg) => {
    if (!currentpg) {
      setCurrentPage(0);
    }
    let page = currentpg || 0;
    let value = {
      userId: "",
      search: searchQuery && searchQuery,
      city: locationFilter !== "" ? locationFilter && locationFilter : "",
      areas: area !== "" ? area && area : [],
      type: type !== "" ? type && type : "",
      shiftingDate: shiftingDate !== "" ? shiftingDate : "",
      occupancyType:
        occupancyFilter && occupancyFilter.length > 0
          ? occupancyFilter
            .filter((property) => property.isSelected === true)
            .map((item) => item.value)
          : [],
      budget: minValue,
      gender: genderFilter !== "" ? genderFilter : "",
      preference: preferencesFilter !== "" ? [preferencesFilter] : [],
    };
    let headers = {
      "Content-Type": "application/json",
    };
    let apiUrl = `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/flatseekers/cijdbci/filter/cijhdciudbub?page=${page}&size=10`;
    const data = await axios.post(apiUrl, value, headers);
    setFilteredProperties(data.data.data.properties);
    setPropData(data.data.data.properties);
    setPropertyCount(data.data.data.totalItems);
  };

  const handlePreferencesChange = (value) => {
    if (value === preferencesFilter) {
      setPreferencesFilter("");
    } else {
      setPreferencesFilter(value);
    }
  };

  const resetfilter = () => {
    setcity("");
    setarea("");
    set_minValue("");
    settype("");
    setOccupancyFilter(
      Occupancy.map((occupancy) => ({ ...occupancy, isSelected: false }))
    );
    setGenderFilter("");
    setPreferencesFilter("");
    setLocationFilter("");
    setShiftingDate("");
  };
  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };
  useEffect(() => {
    // This effect will run when the component mounts and anytime adminId changes
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId]);

  useEffect(() => {
    applyFilters();
  }, [
    locationFilter,
    area,
    occupancyFilter,
    type,
    preferencesFilter,
    BedroomFilter,
    minValue,
    genderFilter,
    furnishedFilter,
    shiftingDate,
    deleted,
    rented,
    searchQuery,
  ]);

  const handleToggle = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/toggleStatus/${adminId}/${id}`
    );
    if (response.ok) {
      window.location.reload();
    } else {
      // console.log(response);
    }
  };

  const handleFilter = (filter) => {
    if (filter === "all") {
      setAll(true);
      setRented(false);
      setDeleted(false);
      // setUnverified(false);
    } else if (filter === "rented") {
      setAll(false);
      setRented(true);
      setDeleted(false);
      // setUnverified(false);
    } else if (filter === "deleted") {
      setAll(false);
      setRented(false);
      setDeleted(true);
      // setUnverified(false);
    }
    // else if (filter === 'unverified') {
    //   setAll(false);
    //   setRented(false);
    //   setDeleted(false);
    //   setUnverified(true);
    // }
    setFilterType(filter);
  };
  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      applyFilters(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      applyFilters(currentPage + 1);
    }
  };

  let totalPages = parseInt(propertyCount / 10);
  let visibleData = filteredProperties;
  if (filteredProperties.length && filteredProperties.length > 10) {
    totalPages = Math.ceil(filteredProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = filteredProperties.slice(startIndex, endIndex);
  }
  const openModal = (propertyId) => {
    // console.log("Opening modal for propertyId:", propertyId);
    setPropertyIdToDelete(propertyId);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    // console.log("Closing modal");
    setModalOpen(false);
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  const handleDeleteProperty = (propertyId) => {
    // Construct the URL with the adminId and propertyId
    const url = `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/delete-property/${adminId}/dcjiebdu/dejbdyw`;

    // Prepare the request body
    const requestBody = {
      id: propertyId,
      deleteReason: deleteReason,
    };

    // Send the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        // console.log("Response status:", response.status);
        // Handle the response here
        if (response.ok) {
          // console.log("Property deleted successfully");
          // You can add code here to handle success, e.g., removing the property from the list
          // Reload the page
          window.location.reload();
        } else {
          console.error("Error deleting property");
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });

    // Close the modal after submitting
    closeModal();
  };
  const navigate = useNavigate();

  const handleEditPropertyButton = (id) => {
    navigate(`/edit-property?id=${id}`);
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle
        tag="h4"
        className="text-center border-bottom p-3 mb-0 d-flex justify-content-between"
      >
        <div className="property-name">
          <i className="bi bi-houses me-2"></i>
          All Flatseekers
        </div>
        <div className="property-count">Total Flatseekers: {propertyCount}</div>
      </CardTitle>
      <CardBody>
        <Modal isOpen={showModal} backdrop="static" keyboard={false}>
          <ModalHeader>
            <span>Password Verification </span>
            <Link to="/starter" style={{ "padding-left": "175px" }}>
              <Button>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </ModalHeader>

          <ModalBody>
            <Form onSubmit={handleModalSubmit}>
              <FormGroup>
                <Label for="superpassword">Password:</Label>
                <Input
                  type="password"
                  id="superpassword"
                  required
                  value={superpassword}
                  onChange={(e) => setSuperpassword(e.target.value)}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
              <Button type="submit">Continue</Button>
            </Form>{" "}
          </ModalBody>
        </Modal>
        <div className="filter-buttons">
          <button
            className="sortButton rounded"
            onClick={handleShow}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog ">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Filter
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <Form>
                    <FormGroup>
                      <Label for="location">Area :</Label>

                      <NearbyLocations
                        handleareaChange={handleareaChange}
                        area={area}
                      />
                      <div className="location-groups">
                        {area &&
                          area.map((item, index) => {
                            console.log(item);
                            return (
                              item != "" && (
                                <div
                                  key={index}
                                  className={`location-button rounded-pill selected`}
                                  onClick={() => {
                                    removearea(item);
                                  }}
                                >
                                  {item}
                                </div>
                              )
                            );
                          })}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="location">City :</Label>
                      <Input
                        id="location"
                        placeholder="search..."
                        value={locationFilter}
                        onChange={(e) => {
                          handleLocationChange(e.target.value);
                        }}
                      />
                      <div className="location-groups mt-2">
                        {cityFilter &&
                          cityFilter.map((button) => (
                            <div
                              key={button.value}
                              className={`location-button rounded-pill ${city === button.cityName ? "selected" : ""
                                }`}
                              value={city}
                              onClick={() => {
                                handleLocationChange(button.cityName);
                              }}
                            >
                              {button.cityName}
                            </div>
                          ))}
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label>Occupancy Type :</Label>
                      <div className="location-groups">
                        {Occupancy.map((button, index) => (
                          <div
                            key={index}
                            className={`location-button rounded-pill ${occupancyFilter[index].isSelected
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleOccupancyChange(index)}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Type :</Label>
                      <div className="location-groups">
                        {filtertype &&
                          filtertype.map((button) => (
                            <div
                              key={button.value}
                              className={`location-button rounded-pill ${type === button.value ? "selected" : ""
                                }`}
                              value={type}
                              onClick={() => {
                                handletypeChange(button.value);
                              }}
                            >
                              {button.value}
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Preferences :</Label>
                      <div className="location-groups">
                        {Preferences.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${preferencesFilter === button.value
                                ? "selected"
                                : ""
                              }`}
                            value={preferencesFilter}
                            onClick={() => {
                              handlePreferencesChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label for="budget">Budget :</Label>
                      <Input
                        type="number"
                        id="budget"
                        value={minValue}
                        onChange={(e) => handleInput(e.target.value)}
                        // min={2000}
                        // max={50000}
                        // step={1000}
                      />
                    </FormGroup>


                    <FormGroup className="mt-3">
                      <Label>Gender :</Label>
                      <div className="location-groups">
                        {gender.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${genderFilter === button.value ? "selected" : ""
                              }`}
                            value={genderFilter}
                            onClick={() => {
                              handleGenderChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label>Shifting Date :</Label>
                      <div className="location-groups">
                        <input
                          type="date"
                          className="form-control"
                          value={shiftingDate}
                          onChange={(e) => setShiftingDate(e.target.value)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <div className="location-groups">
                        <input
                          type="Button"
                          className="form-control btn btn-primary"
                          value={"Reset Filter"}
                          onClick={resetfilter}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handleSearchClick}
            style={{
              height: "40px",
              float: "right",
              borderRadius: "10px",
              padding: "0 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            Search
          </button>
          <input
            type="text"
            placeholder="Search..."
            value={inputValue}
            onChange={handleInputChange}
            style={{
              float: "right",
              height: "40px",
              width: "190px",
              borderRadius: "10px",
              padding: "10px",
              marginRight: "10px"
            }}
          />
        </div>
        <div className="">
          <div className="header">{/* ... */}</div>,
          <div className="row justify-content-center">
            {/* Main Contain */}
            <div className="mainContain py-2 col-md-12">
              <div className="mb-3 flatseeker-card flat-seekerbox">
                {filteredProperties.map((property, index) => (
                  <div
                    className="property-card  row flat-property-card  "
                    key={index}
                    id="propertyCard"
                  >
                    <div
                      className="row no-gutters card-upper"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div
                        className="col-md-4 d-flex  property-image property-new-image"
                        style={{ padding: "0px" }}
                      >
                        <img
                          src={property.profilePhoto}
                          className=" propertyImage"
                          id="CarouselImage"
                          alt="Property"
                        />
                      </div>
                      <div
                        className="col-md-8 propertyNewCard flatseeker-property-card"
                        id="propertyCard-body"
                      >
                        <div className="flatseeker-card-body body-for-laptop">
                          <div
                            className="text-decoration-none text-dark"
                            onClick={() => handleShow(property.id)}
                          >
                            <div id=" card-Heading">
                              <h5
                                className="card-title"
                                style={{
                                  fontSize: "1.3rem",
                                  fontWeight: "bold",
                                  color: "#333",
                                }}
                              >
                                {property.name}
                              </h5>
                              <div id="card-location" className="row">
                                <div
                                  className="col d-flex align-items-center"
                                  id="col"
                                >
                                  <span className="grey flatseekerprefrence">
                                    <FontAwesomeIcon
                                      className="grey"
                                      icon={faLocationDot}
                                    />
                                    <ul>
                                      {property.area
                                        .slice(0, 2)
                                        .map((area, index) => (
                                          <li key={index}>{area}</li>
                                        ))}
                                      {property.area.length > 2 && (
                                        <li>+{property.area.length - 2}</li>
                                      )}
                                    </ul>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div id="card-Details" className="row">
                              <div
                                id="FlatseekerDetails"
                                className="col flat-details"
                              >
                                <span className="grey"> Rent </span>
                                <p>₹ {property.budget}</p>
                              </div>
                              <div
                                id="FlatseekerDetails"
                                className="col flat-details"
                              >
                                <span className="grey"> Looking For </span>
                                <p>{property.gender}</p>
                              </div>
                              {property.flatType && (
                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Looking For </span>
                                  <p>{property.flatType}</p>
                                </div>
                              )}
                            </div>
                            <div className="preferences-details">
                              <ul>
                                {property.preference
                                  .slice(0, 3)
                                  .map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))}
                                {property.preference.length > 5 && (
                                  <li
                                    style={{
                                      backgroundColor: "black",
                                      color: "white",
                                    }}
                                  >
                                    + {property.preference.length - 3}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="model-box mobile-hide-link" style={{ width: "100%" }}>
                            <Modal
                              show={selectedPropertyID === property.id}
                              onHide={handleClose}
                              className="flat-modal-dialog "
                              
                            >
                               <ModalHeader>
                                <button onClick={()=>{setSelectedPropertyID(false)}}>close</button>
                </ModalHeader>
                              <Modal.Body  >
                                <ParticularFlatSeekerProperty
                                  propertyId={property.id}
                                />
                              </Modal.Body>
                            </Modal>
                           
                          </div>
                          
                        </div>
                        <div className="flatseeker-card-body body-for-mobile">
                        
                          <div className="model-box " style={{ width: "100%" }}>
                          <Link
                              className="text-decoration-none text-dark"
                              to={`/ParticularFlatseekerProperty/${property.id}`}
                            >
                              <div id=" card-Heading">
                                {" "}
                              
                                <h2>{property.name} </h2>{" "}
                         
                                <div
                                  id="card-location"
                                  className="row "
                                  style={{}}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    id="col"
                                  >
                                    <span className="grey flatseekerprefrence">
                                      <FontAwesomeIcon
                                        className="grey"
                                        icon={faLocationDot}
                                      />

                                      <ul>
                                        <li>{property.area[0]}</li>
                                        &nbsp; &nbsp;{" "}
                                        {property.area[1] ? "..." : ""}
                                      </ul>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div id="card-Details" className="row">
                          

                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Rent </span>
                                  <p>₹ {property.budget}</p>
                                </div>

                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Looking For </span>{" "}
                                  <p>{property.gender}</p>
                                </div>

                                {property.flatType && (
                                  <div
                                    id="FlatseekerDetails"
                                    className="col flat-details"
                                  >
                                    <span className="grey"> Looking For </span>{" "}
                                    <p>{property.flatType}</p>
                                  </div>
                                )}
                              </div>

                              <div className="preferences-details">
                                <ul>
                                  {property.preference
                                    .slice(0, 3)
                                    .map((item, index) => {
                                      return <li key={index}>{item}</li>;
                                    })}
                                  {property.preference.length > 5 && (
                                    <li
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "black",
                                      }}
                                    >
                                      {property.preference.length - 3} more...
                                    </li>
                                  )}
                                </ul>
                              </div>

                             
                            </Link>
                           
                          </div>
                          
                        </div>
                      </div>
                    </div>

                    {property.isRentedout == "0" ? (
                      ""
                    ) : (
                      <p
                        className="rentout-flatseeker"
                        style={{ backgroundColor: "red" }}
                      >
                        Rentedout
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* pagination part */}
          {propertyCount > 10 && (
            <div className="paginationBox">
              <Button
                className="paginationBtn"
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
              >
                {" "}
                <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
              </Button>
              <span>{currentPage + 1}</span>
              <Button className="paginationBtn" onClick={goToNextPage} disabled={currentPage === totalPages + 1}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          )}
        </div>
      </CardBody>
      <style>
        {`
          .flat-seekerbox {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
          }

          .flat-seeker-card {
              display: flex;
              padding: 15px;
              margin-bottom: 0px;
          }

          .flatseekerprefrence {
              list-style: none;
          }

          #propertyCard {
              display: flex;
              padding: 15px;
              margin: 0px;
              border-radius: 16px;
              border: 1px solid #0000001a;
              box-shadow: rgb(40 125 253 / 8%) 0px 7px 29px 0px;
          }

          .flatseeker-card .property-card .property-image {
              width: 25%;
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
          }

          .flatseeker-card .property-card .propertyNewCard {
              width: 75%;
          }

          #propertyCard-body {
              padding: 0px;
          }

          .flatseekerprefrence {
              display: flex;
              width: 100%;
              align-items: center;
              font-size: 12px;
              gap: 5px;
          }

          .flatseekerprefrence ul {
              display: flex;
              list-style-type: none;
              gap: 5px;
              margin: 0;
              padding: 0;
          }

          .flatseekerprefrence ul li {
              list-style-type: none;
              text-transform: capitalize;
          }

          .preferences-details ul {
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              padding: 0px;
              gap: 10px;
              align-items: center;
          }

          .preferences-details ul li {
              font-size: 10px;
              background: black;
              color: white;
              padding: 3px 5px;
              border-radius: 4px;
              text-transform: capitalize;
              list-style-type: none;
              letter-spacing: 1px;
          }

          .propertyNewCard h2 {
              margin-bottom: 0px;
              min-height: 0px;
              font-size: 18px;
              padding-top: 8px;
              font-weight: 600;
              text-transform: capitalize;
              color: rgba(0, 0, 0, 0.65);
          }

          .flatseeker-card .property-card #CarouselImage {
              border: 1px solid #0000005c;
              object-fit: cover;
              height: 80px;
              width: 80px;
              border-radius: 50%;
          }
        `}
      </style>
    </Card>
  );
};

export default AllFlatSeekers;
