import React, { useState } from "react";
import axios from "axios";
import "./BulkUpload.css"; // Separate CSS for styling

const BulkUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [notUploadedProperties, setNotUploadedProperties] = useState([]);

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if a file is selected
    if (!file) {
      setResponseMessage("Please select a file.");
      return;
    }

    setLoading(true); // Show loader during the API call

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://auth.citynect.in/app/citynect-protected/jidfigufh/fhigfyfeg/ckodih/upload-file/mix",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Assuming we get a response like the one shown in the description
      if (response.status === 200) {
        setResponseMessage(response.data.message || "Upload successful.");
        // setNotUploadedProperties(response.data.notUploadedProperties);
      } else {
        setResponseMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error during the upload:", error);
      setResponseMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false); // Hide loader after the request is done
    }
  };

  return (
    <div className="bulk-upload-container">
      <div className="custome-container-bulk">
        <h2>Bulk Upload</h2>
        <form onSubmit={handleSubmit} className="upload-form">
          <div className="form-group">
            <label htmlFor="file">Select Excel File</label>
            <input
              type="file"
              id="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
          </div>

          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? "Uploading..." : "Upload"}
          </button>
        </form>

        {/* Display loader */}
        {loading && (
          <p className="loading-message">Uploading file, please wait...</p>
        )}

        {/* Display the response message */}
        {responseMessage && (
          <p className="response-message">{responseMessage}</p>
        )}

        {/* Scrollable list of not uploaded properties */}
        {notUploadedProperties.length > 0 && (
          <div className="response-container">
            <h3>Not Uploaded Properties</h3>
            <div className="scrollable-log">
              {notUploadedProperties.map((property, index) => (
                <div key={index} className="property-item">
                  <p>Number: {property.number}</p>
                  <p>Address: {property.address}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkUpload;
