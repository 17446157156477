import React, { useState, useEffect } from "react";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faCircleXmark,
  faClose,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValid } from "date-fns"; // It's recommended to rename isValid function imported from date-fns to avoid conflict
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import getMobileOS from "../../../helpers/os";
import "./step.css";

const Step3 = ({
  propertyDetails,
  flatSeekerDetails,
  updateFlatSeekerDetails,
  currentStep,
  setCurrentStep,
  handleInputChangeFlat,
}) => {
  const initialAdditionalCosts = {
    description1: "",
    cost1: "",
  };

  const [errors, setErrors] = useState({
    name: "",
    error: "",
  });

  const [additionalCosts, setAdditionalCosts] = useState(
    initialAdditionalCosts
  );

  const [isOpen, setIsOpen] = useState(false);
  const [addInst, setAddInst] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [area, setArea] = useState();
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    updateFlatSeekerDetails((prevState) => ({ ...prevState, [name]: checked }));
  };

  const onChange = (value, field) => {


    if (flatSeekerDetails.newCity && flatSeekerDetails.newCity.trim() !== "") {
      updateFlatSeekerDetails((prevState) => {
        const trimmedCity = prevState.newCity.trim();
        return {
          ...prevState,
          city: [...prevState.city, trimmedCity],
          newCity: "", // Clear the input box
        };
      });
    }



    const newDetails = { ...flatSeekerDetails, [field]: value };
    updateFlatSeekerDetails(newDetails);


    // Ensure updateFlatSeekerDetails is called as a function
  };

  const os = getMobileOS();

  // const validateForm = () => {
  //   const newErrors = {};
  //   const userId = localStorage.getItem("user");
  //   const ownerId = { ...flatSeekerDetails, ownerId: userId };
  //   updateFlatSeekerDetails(ownerId);

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setError("Please upload only one photo.");
      return;
    }
    const file = acceptedFiles[0];
    if (file) {
      updateFlatSeekerDetails((prevState) => ({
        ...prevState,
        profilePhoto: URL.createObjectURL(file),
      }));
      setPhoto(file);
      setError(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "image/jpeg, image/png, image/jpg, image/webp, image/gif, image/bmp",
    onDrop: handleDrop,
    maxFiles: 1, // Restrict to only one file
  });

  const [thankyoumodal, setThankyoumodal] = useState(false);
  const Navigate = useNavigate();
  const thankyouModal = () => {
    if (success === "Error") {
      setThankyoumodal(false);
    } else {
      if (os === "Android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.codingislife.citynect";
      } else if (os === "iOS") {
        window.location.href =
          "https://apps.apple.com/app/your_app_name/idyour_app_id";
      } else {
        Navigate("/myaccount/listedproperty");
      }
    }
  };

  const handleSubmit = async () => {
    const isValid = validateflatSeekerDetails();
    console.log(isValid);
    if (isValid) {
      setLoading(true);
      setThankyoumodal(true);
      try {
        // await makearea()
        const formData = new FormData();
        formData.append(
          "flatseeker",
          JSON.stringify({
            ownerId: flatSeekerDetails.ownerId,
            name: flatSeekerDetails.name,
            number: propertyDetails.phone,
            type: flatSeekerDetails.type,
            flatType: flatSeekerDetails.flatType,
            preference: flatSeekerDetails.preference,
            area: flatSeekerDetails.area,
            city: flatSeekerDetails.city,
            budget: flatSeekerDetails.budget,
            furnishedType: flatSeekerDetails.furnishedType,
            occupancyType: flatSeekerDetails.occupancyType,
            noOfPerson: flatSeekerDetails.noOfPerson,
            shiftingDate: flatSeekerDetails.shiftingDate,
            gender: flatSeekerDetails.gender,
            callAllowed: flatSeekerDetails.callAllowed,
            description: flatSeekerDetails.description,
          })
        );

        if (photo) {
          formData.append("photos", photo);
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/v2/list-flatseeker/add-flatseeker`,
          formData
        );
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
        } else {
          setSuccess("Error");
          setLoading(false);
          console.error("Failed to submit FlatSeeker:", response.status);
        }
      } catch (error) {
        setLoading(false);
        setSuccess("Error");
        console.error("Error submitting Flatseeker:", error);
      }
    }
  };

  const preferenceTypes = [
    { value: "smoking", label: "Smoking" },
    { value: "alcoholEnthusiast", label: "Alcohol Enthusiast" },
    { value: "adventureSeeker", label: "Adventure Seeker" },
    { value: "healthConscious", label: "Health Conscious" },
    { value: "petLover", label: "Pet Lover" },
    { value: "socialButterfly", label: "Social Butterfly" },
    { value: "foodie", label: "Foodie" },
    { value: "nightOwl", label: "Night Owl" },
    { value: "earlyRiser", label: "Early Riser" },
    { value: "vegetarian", label: "Vegetarian" },
  ];

  const validateflatSeekerDetails = () => {
    const errors = {};
    console.log(errors);

    // Validation logic
    if (flatSeekerDetails.type === "") {
      errors.type = "Please select a type.";
    }
    if (!flatSeekerDetails.budget) {
      errors.budget = "Please enter the budget.";
    }
    if (flatSeekerDetails.area.length === 0) {
      errors.area = "Please select at least one area.";
    }

    if (!flatSeekerDetails.city) {
      errors.city = "Please enter the city.";
    }
    if (!flatSeekerDetails.description) {
      errors.description = "Please enter the description.";
    }
    if (flatSeekerDetails.furnishedType.length === 0) {
      errors.furnishedType = "Please select at least one furnished type.";
    }
    if (!flatSeekerDetails.profilePhoto) {
      errors.profilePhoto = "Please upload a profile photo.";
    }
    if (!flatSeekerDetails.gender) {
      errors.gender = "Please select a gender.";
    }
    if (!flatSeekerDetails.shiftingDate) {
      errors.shiftingDate = "Please select a shifting date.";
    }

    // Check for specific type-related validations
    if (
      flatSeekerDetails.type === "private flat" &&
      !flatSeekerDetails.flatType
    ) {
      errors.flatType = "Please select a flat type.";
    }
    if (flatSeekerDetails.type === "pg" && flatSeekerDetails.noOfPerson === 0) {
      errors.noOfPerson = "Please select the number of persons.";
    }
    console.log(flatSeekerDetails.occupancyType);
    if (
      flatSeekerDetails.type !== "private flat" &&
      flatSeekerDetails.occupancyType.length <= 0
    ) {
      errors.occupancyType = "Please select an occupancy type.";
    }

    // Set the errors state
    setErrors(errors);

    // Return validity status
    return Object.keys(errors).length === 0;
  };

  const handleareaChange = (field, value) => {
    setArea({
      ...area,
      [field]: value,
    });
    const updatedArea = { ...flatSeekerDetails, area: area };
    updateFlatSeekerDetails(updatedArea);
  };

  const removeAreaInput = (index) => {
    updateFlatSeekerDetails((prevState) => ({
      ...prevState,
      area: prevState.area.filter((_, i) => i !== index), // Remove the area at the specified index
    }));
  };
  const handleSelect = (fullarea) => {
    if (fullarea) {
      const pinCodeMatch = fullarea.match(/\b\d{6}\b/);
      const zipCode = pinCodeMatch ? pinCodeMatch[0] : null;
      const selectedArea = fullarea.split(",")[0].trim();
      const city = fullarea.split(",")[1].trim();

      // Update flatSeekerDetails with the selected area and possibly city
      updateFlatSeekerDetails((prevState) => {
        const newDetails = {
          ...prevState,
          newArea: selectedArea,
        };

        // Set the city if it's not already set
        if (!prevState.city) {
          newDetails.city = city;
        }

        return newDetails;
      });
    }
  };

  const addAreaInput = () => {
    if (flatSeekerDetails.newArea && flatSeekerDetails.newArea.trim() !== "") {
      updateFlatSeekerDetails((prevState) => ({
        ...prevState,
        area: [...prevState.area, prevState.newArea.trim()],
        newArea: "", // Clear the input box
      }));
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const input = document.getElementById("area");
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["(regions)"], // Restrict to regions
        componentRestrictions: { country: "IN" }, // Restrict to India
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        handleSelect(place.formatted_address); // Use formatted_address to get full address
      });
    };

    return () => {
      window.initAutocomplete = null;
    };
  }, []);

  return (
    <div className="container row" id="step3" style={{ margin: "30px 1rem" }}>
      {/* Navigation Section */}
      <div className="col-md-4 col-lg-3">
        <div className="step-navigation" id="progressBar">
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Basic Details
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 1
            </small>
          </div>
          <div className={"step active"}>
            <span className="step-number active "></span>
            Proporty Profile
          </div>
          <div className="d-flex">
            <div className="connector" style={{ visibility: "hidden" }}></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 2
            </small>
          </div>
        </div>
        <div className="mt-3" id="needHelp">
          <h2>Need Help ?</h2>
          <h6 style={{ opacity: "0.8" }}>Contact us Now</h6>
          <span>
            <FontAwesomeIcon icon={faPhone} /> +91-9316066832{" "}
          </span>
        </div>
      </div>
      <div className="col-md-6 col-lg-5" id="middle">
        {/* Back Button */}
        <div
          role="button"
          id="backDiv"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          {"  "} Back
        </div>

        {/* Step Heading */}
        <h4 className="MiddleHeading my-4">Add Proporties details...</h4>

        {/* Type */}
        <FormGroup style={{ display: "flex", gap: "10px" }}>
          <FormGroup>
            <label
              className={`radio-button rounded-pill ${flatSeekerDetails?.type === "pg" ? "selected" : ""
                }`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                name="type"
                value="pg"
                checked={flatSeekerDetails?.type === "pg"}
                onChange={(e) => onChange(e.target.value, "type")}
                required
              />
              PG
            </label>
          </FormGroup>

          <FormGroup>
            <label
              className={`radio-button rounded-pill ${flatSeekerDetails?.type === "sharing flat" ? "selected" : ""
                }`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                name="type"
                value="sharing flat"
                checked={flatSeekerDetails?.type === "sharing flat"}
                onChange={(e) => onChange(e.target.value, "type")}
                required
              />
              Sharing Flat
            </label>
          </FormGroup>

          <FormGroup>
            <label
              className={`radio-button rounded-pill ${flatSeekerDetails?.type === "private flat" ? "selected" : ""
                }`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                name="type"
                value="private flat"
                checked={flatSeekerDetails?.type === "private flat"}
                onChange={(e) => onChange(e.target.value, "type")}
                required
              />
              Private Flat
            </label>
          </FormGroup>
        </FormGroup>

        {/* Flat Type (visible only when user selects private flat) */}
        {flatSeekerDetails?.type === "private flat" && (
          <>
            <label style={{ fontWeight: "500" }}>Bedrooms:</label>

            <FormGroup style={{ display: "flex", gap: "10px" }}>
              <FormGroup>
                <label
                  className={`radio-button rounded-pill ${flatSeekerDetails.flatType === "1rk" ? "selected" : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="flatType"
                    value="1rk"
                    checked={flatSeekerDetails.flatType === "1rk"}
                    onChange={(e) => onChange(e.target.value, "flatType")}
                    required={flatSeekerDetails.type === "private flat"}
                  />
                  1 RK
                </label>
              </FormGroup>

              <FormGroup>
                <label
                  className={`radio-button rounded-pill ${flatSeekerDetails.flatType === "1 BHK" ? "selected" : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="flatType"
                    value="1 BHK"
                    checked={flatSeekerDetails.flatType === "1 BHK"}
                    onChange={(e) => onChange(e.target.value, "flatType")}
                    required={flatSeekerDetails.type === "private flat"}
                  />
                  1 BHK
                </label>
              </FormGroup>

              <FormGroup>
                <label
                  className={`radio-button rounded-pill ${flatSeekerDetails.flatType === "2 BHK" ? "selected" : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="flatType"
                    value="2 BHK"
                    checked={flatSeekerDetails.flatType === "2 BHK"}
                    onChange={(e) => onChange(e.target.value, "flatType")}
                    required={flatSeekerDetails.type === "private flat"}
                  />
                  2 BHK
                </label>
              </FormGroup>

              <FormGroup>
                <label
                  className={`radio-button rounded-pill ${flatSeekerDetails.flatType === "3 BHK" ? "selected" : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="flatType"
                    value="3 BHK"
                    checked={flatSeekerDetails.flatType === "3 BHK"}
                    onChange={(e) => onChange(e.target.value, "flatType")}
                    required={flatSeekerDetails.type === "private flat"}
                  />
                  3 BHK
                </label>
              </FormGroup>

              <FormGroup>
                <label
                  className={`radio-button rounded-pill ${flatSeekerDetails.flatType === "4 BHK" ? "selected" : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="flatType"
                    value="4 BHK"
                    checked={flatSeekerDetails.flatType === "4 BHK"}
                    onChange={(e) => onChange(e.target.value, "flatType")}
                    required={flatSeekerDetails.type === "private flat"}
                  />
                  4 BHK
                </label>
              </FormGroup>
              {errors.flatType && (
                <div className="error-message">{errors.flatType}</div>
              )}
            </FormGroup>
          </>
        )}

        {/* Preference */}
        <FormGroup>
          <label>Preferences:</label>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {preferenceTypes.map((type) => (
              <label
                key={type.value}
                className={`radio-button rounded-pill ${flatSeekerDetails?.preference &&
                    flatSeekerDetails?.preference?.includes(type.value)
                    ? "selected"
                    : ""
                  }`}
              >
                <input
                  style={{ display: "none" }}
                  type="checkbox"
                  name="preference"
                  value={type.value}
                  checked={
                    flatSeekerDetails?.preference &&
                    flatSeekerDetails?.preference?.includes(type.value)
                  }
                  onChange={(e) => {
                    const selectedType = e.target.value;
                    const isChecked = e.target.checked;
                    let updatedTypes;
                    if (isChecked) {
                      updatedTypes = [
                        ...(flatSeekerDetails?.preference || []),
                        selectedType,
                      ];
                    } else {
                      updatedTypes = (
                        flatSeekerDetails?.preference || []
                      ).filter((item) => item !== selectedType);
                    }
                    onChange(updatedTypes, "preference");
                  }}
                />
                {type.label}
              </label>
            ))}
          </div>
          {errors.preferenceTypes && (
            <div className="error-message">{errors.preferenceTypes}</div>
          )}
        </FormGroup>
        <FormGroup>
          <label className="mb-0">Area:</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
              position: "relative",
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevent form submission if inside a form
                addAreaInput();
              }
            }}
          >
            <Input
              type="text"
              value={flatSeekerDetails?.newArea}
              id="area"
              onChange={(e) => onChange(e.target.value, "newArea")} // Corrected onChange
              required
            />
            <Button
              style={{
                cursor: "pointer",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "18px",
                position: "absolute",
                right: "2%",
                fontWeight: "800",
                color: "#287dfd",
              }}
              onClick={() => addAreaInput()}

            >
              +
            </Button>
          </div>
          <div
            className="addea-area-box"
            style={{ display: "flex", gap: "5px" }}
          >
            {flatSeekerDetails?.area?.map((area, index) => (
              <div
                className="added-areas"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  border: "1px solid #287dfd",
                  borderRadius: "20px",
                  backgroundColor: "#287dfd15",
                  marginTop: "10px",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  style={{
                    cursor: "pointer",
                    fontSize: "9px",
                    color: "grey",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => removeAreaInput(index)}
                >
                  X
                </Button>
                <div>{area}</div>
              </div>
            ))}
          </div>
          {errors.area && <div className="error-message">{errors.area}</div>}
        </FormGroup>

        <FormGroup>
          <label>City:</label>
          <Input
            type="text"
            name="city"
            value={flatSeekerDetails?.city}
            onChange={(e) => onChange(e.target.value, "city")}
            required
          />
          {errors.city && <div className="error-message">{errors.city}</div>}
        </FormGroup>

        {/* Budget */}
        <FormGroup>
          <label>Budget:</label>
          <Input
            type="number"
            name="budget"
            value={flatSeekerDetails?.budget}
            onChange={(e) => {
              // Check if the input value has more than 8 digits
              if (e.target.value.length <= 8) {
                onChange(e.target.value, "budget");
              }
            }}
            maxLength={8}
            required
          />

          {errors.budget && (
            <div className="error-message">{errors.budget}</div>
          )}
        </FormGroup>

        {/* Furnished Type */}
        <FormGroup>
          <label>Furnished Type:</label>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {["fully-furnished", "semi-furnished", "unfurnished"].map(
              (type) => (
                <label
                  key={type}
                  className={`radio-button rounded-pill ${flatSeekerDetails?.furnishedType &&
                      flatSeekerDetails?.furnishedType.includes(type)
                      ? "selected"
                      : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="checkbox"
                    name="furnishedType"
                    value={type}
                    checked={
                      flatSeekerDetails?.furnishedType &&
                      flatSeekerDetails?.furnishedType.includes(type)
                    }
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      const isChecked = e.target.checked;
                      let updatedTypes;
                      if (isChecked) {
                        updatedTypes = [
                          ...(flatSeekerDetails?.furnishedType || []),
                          selectedType,
                        ];
                      } else {
                        updatedTypes = (
                          flatSeekerDetails?.furnishedType || []
                        ).filter((item) => item !== selectedType);
                      }
                      onChange(updatedTypes, "furnishedType");
                    }}
                  />
                  {type}
                </label>
              )
            )}
          </div>
          {errors.furnishedType && (
            <div className="error-message">{errors.furnishedType}</div>
          )}
        </FormGroup>

        {flatSeekerDetails?.type !== "private flat" && (
          <FormGroup>
            <label>Occupancy Types :</label>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {["single", "double", "triple"].map((type) => (
                <label
                  key={type}
                  className={`radio-button rounded-pill ${flatSeekerDetails?.occupancyType &&
                      flatSeekerDetails?.occupancyType?.includes(type)
                      ? "selected"
                      : ""
                    }`}
                >
                  <input
                    style={{ display: "none" }}
                    type="checkbox"
                    name="occupancyTypes"
                    value={type}
                    checked={
                      flatSeekerDetails?.occupancyType &&
                      flatSeekerDetails?.occupancyType?.includes(type)
                    }
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      const isChecked = e.target.checked;
                      let updatedTypes;
                      if (isChecked) {
                        updatedTypes = [
                          ...(flatSeekerDetails?.occupancyType || []),
                          selectedType,
                        ];
                      } else {
                        updatedTypes = (
                          flatSeekerDetails?.occupancyType || []
                        ).filter((item) => item !== selectedType);
                      }
                      onChange(updatedTypes, "occupancyType");
                    }}
                  />
                  {type}
                </label>
              ))}
            </div>
            {errors.occupancyType && (
              <div className="error-message">{errors.occupancyType}</div>
            )}
          </FormGroup>
        )}

        <FormGroup>
          <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
            Upload Photo:
          </Label>
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag &amp; drop a photo here, or click to select a file</p>
          </div>
          {photo && (
            <div className="mt-4">
              <p>Uploaded Photo:</p>
              <img
                src={URL.createObjectURL(photo)}
                alt="Uploaded"
                className="uploaded-photo"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          )}
          {errors.profilePhoto && (
            <div className="error-message text-danger">
              {errors.profilePhoto}
            </div>
          )}
          {error && (
            <div className="error-message text-danger">
              {error}
            </div>
          )}
        </FormGroup>
        {/* Description */}
        <FormGroup>
          <label>Description:</label>
          <Input
            type="text"
            name="description"
            value={flatSeekerDetails?.description}
            onChange={(e) => onChange(e.target.value, "description")}
            required
          />
          {errors.description && (
            <div className="error-message">{errors.description}</div>
          )}
        </FormGroup>

        {/* Number of Persons */}
        <FormGroup>
          <label>Number of Persons:</label>
          <Input
            type="select"
            name="noOfPerson"
            value={flatSeekerDetails?.noOfPerson}
            onChange={(e) => onChange(parseInt(e.target.value), "noOfPerson")}
            required
          >
            {[1, 2, 3, 4].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </Input>
          {errors.noOfPerson && (
            <div className="error-message">{errors.noOfPerson}</div>
          )}
        </FormGroup>

        {/* Shifting Date */}
        <FormGroup>
          <label>Shifting Date:</label>
          <Input
            type="date"
            name="shiftingDate"
            value={flatSeekerDetails?.shiftingDate}
            onChange={(e) => onChange(e.target.value, "shiftingDate")}
            required
          />
          {errors.shiftingDate && (
            <div className="error-message">{errors.shiftingDate}</div>
          )}
        </FormGroup>

        {/* Gender */}
        <FormGroup>
          <label>Gender:</label>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>
              <label
                className={`radio-button rounded-pill ${flatSeekerDetails?.gender === "male" ? "selected" : ""
                  }`}
              >
                <Input
                  style={{ display: "none" }}
                  type="radio"
                  name="gender"
                  value="male"
                  checked={flatSeekerDetails?.gender === "male"}
                  onChange={(e) =>
                    updateFlatSeekerDetails({
                      ...flatSeekerDetails,
                      gender: e.target.value,
                    })
                  }
                  required
                />{" "}
                Male
              </label>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <label
                className={`radio-button rounded-pill ${flatSeekerDetails?.gender === "female" ? "selected" : ""
                  }`}
              >
                <Input
                  style={{ display: "none" }}
                  type="radio"
                  name="gender"
                  value="female"
                  checked={flatSeekerDetails?.gender === "female"}
                  onChange={(e) =>
                    onchange({ ...flatSeekerDetails, gender: e.target.value })
                  }
                />{" "}
                Female
              </label>
            </div>
            <div>
              <label
                className={`radio-button rounded-pill ${flatSeekerDetails?.gender === "other" ? "selected" : ""
                  }`}
              >
                <Input
                  style={{ display: "none" }}
                  type="radio"
                  name="gender"
                  value="other"
                  checked={flatSeekerDetails?.gender === "other"}
                  onChange={(e) =>
                    onchange({ ...flatSeekerDetails, gender: e.target.value })
                  }
                />{" "}
                Other
              </label>
            </div>
          </div>
          {errors.gender && (
            <div className="error-message">{errors.gender}</div>
          )}
        </FormGroup>

        {/* Call Allowed */}
        <FormGroup check>
          <label check>
            <Input
              type="checkbox"
              name="callAllowed"
              checked={flatSeekerDetails?.callAllowed}
              onChange={handleCheckboxChange}
              required
            />
            Call Allowed
          </label>
          {errors.callAllowed && (
            <div className="error-message">{errors.callAllowed}</div>
          )}
        </FormGroup>

        {/* Button Section */}
        <div className="ButtonBox mt-4">
          <Button
            className="previousBtn rounded margin-demo"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </Button>
          <Button
            className="continueBtn rounded margin-demo1"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      {thankyoumodal && (
        <div className="thankyoumodal">
          <div className="modal-overlay">
            <div className="thankyouModal">
              <div className="modal-content">
                {success === true && (
                  <div className="success-message">
                    <FontAwesomeIcon icon={faCircleCheck} size="6x" />
                    <h2>Property Successfully Listed!</h2>
                    <p>Thank you for listing your property with us.</p>
                    <button className="btn btn-success" onClick={thankyouModal}>
                      Go to My Account
                    </button>
                  </div>
                )}
                {success === "Error" && (
                  <div className="error-message">
                    <FontAwesomeIcon icon={faCircleXmark} size="6x" />
                    <h2>Error</h2>
                    <p>There was an error while listing your property. Please try again later.</p>
                    <button className="btn btn-danger" onClick={() => setThankyoumodal(false)}>
                      Close
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Step3;
