import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./ListProperty.css";
import Step2 from "./EditProperty/step2.js";
import Step3 from "./EditProperty/step3";
import Step4 from "./EditProperty/step4.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const EditProperty = () => {
  const [currentStep, setCurrentStep] = useState(1);
  //   const [propertyData, setPropertyData] = useState({});
  // let propertyData = {};

  const [propertyDetails, setPropertyDetails] = useState({
    name: "",
    phone: "",
    type: "",
    subtype: "",
    bedroom: 0,
    bathroom: 0,
    sharing: "",
    sharingType:"",
    withoutFood: 0,
    memberedAllowed: "",
    currentlyLiving: 0,
    vacancyAvailable: 0,
    nonVegAllowed: 0,
    furnishedType: "",
    amenities: [],
    availableFrom: Date.now(),
    rent: "",
    totalFlatRent: "",
    rentEachHead: "",
    isNegotiable: false,
    deposit: "",
    instructions: "",
    preference: "",
    photoUrls: [],
    additionalCosts: {
      description1: "",
      cost1: "",
      description2: "",
      cost2: "",
      description3: "",
      cost3: "",
    },
    address: {
      houseno: "",
      area: "",
      pincode: "",
      streetAddress: "",
      link: "",
      latitude: "",
      logitude: "",
    },
    isVerified: 0,
    isRentedout: 0,
    isDeleted: 0,
    isReported: 0,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Access a specific query parameter by name
  const paramValue = queryParams.get("id");

  useEffect(() => {
    if (!paramValue || paramValue === "") navigate("/all-properties");
    const call = async (id) => {
      try {
        // Changed to axios.post and added an empty object as the body
        const res = await axios.post(
          `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/cjidfncdjf/propertyforadmin/${id}/fkjfbuirfbrf`,
          {}
        );
        const propData = JSON.parse(JSON.stringify(res.data));
        console.log(propData);
        
        setPropertyDetails(propData);
      } catch (error) {
        // console.log(error);
      }
    };

    call(paramValue);
  }, [paramValue, navigate])
console.log(propertyDetails);

  const updatePropertyDetails = (newDetails) => {
    setPropertyDetails(newDetails);
  };
  const onChange = (value, field) => {
    setPropertyDetails({
      ...propertyDetails,
      [field]: value,
    });
    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };
  const handleInputChange = (e) => {
    setPropertyDetails({
      ...propertyDetails,
      [e.target.name]: e.target.value,
    });
  };
  const radioButtons = [
    { value: "pg", label: "PG" },
    { value: "sharing flat", label: "Sharing Flat" },
    { value: "private flat", label: "Private Flat" },
  ];
  const radioButtonSubtype = [
    { value: "Flat", label: "Flat" },
    { value: "Studio Apartment", label: "Studio Apartment" },
    { value: "Independent House/Villa", label: "Independent House/Villa" },
  ];

  const [errors, setErrors] = useState({
    name: "",
    error: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!propertyDetails.phone) {
      newErrors.name = "phone";
      newErrors.error = "Phone number is required.";
    } else if (propertyDetails.phone.length !== 10) {
      newErrors.name = "phone";
      newErrors.error = "Phone number must be 10 digits.";
    }
    if (!propertyDetails.name) {
      newErrors.name = "name";
      newErrors.error = "Owner name is required.";
    }
    if (!propertyDetails.subtype) {
      newErrors.name = "subtype";
      newErrors.error = "Please select a property type.";
    }
    if (!propertyDetails.type) {
      newErrors.name = "type";
      newErrors.error = "Please select a category.";
    }
    if (!propertyDetails.title) {
      newErrors.name = "title";
      newErrors.error = "Please enter the title.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleNextClick = () => {
    const isValid = validateForm();
    if (isValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  console.log(propertyDetails)

  return (
    <div className="MainForListProperty" style={{ overflowX: "hidden" }}>
      {currentStep === 1 && (
        <div className="row justify-content-center">
          <div className="col-lg-5" id="formBox">
            <h3 className="mb-4 text-center">Let's start</h3>
            <Form>
              <FormGroup>
                <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Select category :
                </Label>
                <div className="radio-groups">
                  {radioButtons.map((button) => (
                    <div
                      key={button.value}
                      className={`radio-button rounded-pill ${
                        propertyDetails.type === button.value ? "selected" : ""
                      }`}
                      onClick={() => onChange(button.value, "type")}
                    >
                      {button.label}
                    </div>
                  ))}
                </div>
                {errors.name === "type" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Type of property :
                </Label>
                <div className="radio-groups">
                  {radioButtonSubtype.map((button) => (
                    <div
                      key={button.value}
                      className={`radio-button rounded-pill ${
                        propertyDetails.subtype === button.value
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => onChange(button.value, "subtype")}
                    >
                      {button.label}
                    </div>
                  ))}
                </div>
                {errors.name === "subtype" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label id="labelforMobileView"> Owner Details :</Label>
                <Input
                  type="text"
                  value={propertyDetails.name}
                  onChange={handleInputChange}
                  name="name"
                  id="name"
                  placeholder="Owner Name"
                />
                {errors.name === "name" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="number"
                  value={propertyDetails.phone}
                  onChange={handleInputChange}
                  name="phone"
                  id="phone"
                  placeholder="Owner Phone Number"
                  maxLength={10}
                  minLength={10}
                />
                {/* to add icons before this  */}
                {errors.name === "phone" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  value={propertyDetails.title}
                  onChange={handleInputChange}
                  name="title"
                  id="title"
                  placeholder="Enter Property Title"
                />
                {/* to add icons before this  */}
                {errors.name === "title" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#287DFD",
                    border: "none",
                    height: "40px",
                  }}
                  onClick={handleNextClick}
                >
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </FormGroup>
            </Form>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <Step2
          propertyDetails={propertyDetails}
          updatePropertyDetails={updatePropertyDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChange}
          setPropertyDetails={setPropertyDetails}

          
        />
      )}
      {/* {currentStep === 2 && propertyDetails.type === "flatseeker" && (
        <Step3
          flatSeekerDetails={flatSeekerDetails}
          propertyDetails={propertyDetails}
          updateFlatSeekerDetails={updateFlatSeekerDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChangeFlat}
        />
      )} */}
      {currentStep === 3 && (
        <Step4
          propertyDetails={propertyDetails}
          updatePropertyDetails={updatePropertyDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChange}
        />
      )}
      {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
    </div>
  );
};

export default EditProperty;
