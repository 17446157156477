import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import Cookies from "js-cookie";

// RequestCallbackTable Component
const RequestCallback = () => {
const [superpassword, setSuperpassword] = useState("1");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [requestId, setSelectedrequestId] = useState(null);
  const [requstCallUpdateModal, setrequstCallUpdateModal] = useState(false);
  const [description, setDescription] = useState("");
  const adminId = Cookies.get("id");

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
  ];
  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };
  useEffect(() => {
    // This effect will run when the component mounts and anytime adminId changes
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/user/get-all-request/dkjcbdh/kmdcd`,
          {
            method: "POST", // Set the request method to POST
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(yourDataObject),  // If you have data to send in the request body
          }
        );

        if (response.ok) {
          const data = await response.json();
          setRequests(data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const toggleChangeRequestStatus = () => {
    setrequstCallUpdateModal(!requstCallUpdateModal);
  };

  const handleCallbackStatusChange = () => {
    // Perform the password change operation here
    // You can use selectedAdminId and newPassword to change the admin's password
    const requestUpdateID = requestId;
    if (requestUpdateID) {
      fetch(`${process.env.REACT_APP_API_IP}/user/reqest-update/${adminId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: requestUpdateID, // Add id with the value of requestUpdateID
          description: description,
        }),
      })
        .then((res) => {
          if (res.ok) {
            alert("Request Callback status updated Successfully");
            window.location.reload();
          } else {
            // Handle password change error
            console.error("Failed to change Request Callback status");
          }
        })
        .catch((err) => {
          console.error(err);
          // Handle password change error
        });
    }
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
        <i className="bi bi-telephone-outbound me-2"></i>
        Request Callbacks{" "}
      </CardTitle>
      <CardBody style={{ overflowX: "auto" }}>
        <div>
          <Modal isOpen={showModal}>
            <ModalHeader>
              <span>Password Verification </span>
              <Link to="/starter" style={{ "padding-left": "175px" }}>
                <Button>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </Link>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleModalSubmit}>
                <FormGroup>
                  <Label for="superpassword">Password:</Label>
                  <Input
                    type="password"
                    id="superpassword"
                    required
                    value={superpassword}
                    onChange={(e) => setSuperpassword(e.target.value)}
                  />
                </FormGroup>
                {error && <p className="text-danger">{error}</p>}
                <Button type="submit">Continue</Button>
              </Form>{" "}
            </ModalBody>
          </Modal>

          <Modal isOpen={requstCallUpdateModal}>
            <ModalHeader>Call Status</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="newPassword">Description:</Label>
                  <Input
                    type="text"
                    id="newPassword"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
                <Button className="mx-2" onClick={handleCallbackStatusChange}>
                  Submit
                </Button>
                <Button onClick={toggleChangeRequestStatus}>Cancel</Button>
              </Form>
            </ModalBody>
          </Modal>

          {/* <h3 className="text-center">Request Callbacks</h3> */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr.</th>
                {/* <th>ID</th> */}
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Created On</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request, index) => (
                <tr key={request.id}>
                  <th scope="row">{index + 1}</th>
                  {/* <td>{request.id}</td> */}
                  <td>{request.name}</td>
                  <td>{request.phone}</td>
                  <td>{request.email}</td>
                  <td>{request.created_On.split("T")[0]}</td>
                  
                  <td>
                    <Button
                      onClick={() => {
                        setSelectedrequestId(request.id);
                        toggleChangeRequestStatus();
                      }}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default RequestCallback;
