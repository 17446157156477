import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const AddCredit = () => {
  const [contact, setContact] = useState("");
  const [credit, setCredit] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a data object to send in the request body
    const data = {
      number: contact,
      credit: credit,
    };

    fetch(
      "https://auth.citynect.in/admin/citynect-only/protected/v1/eijfnirfrfb/add-credit/fdfkjbeij/kfbejibij/fljkenfji",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // Convert the data object to JSON
      }
    )
      .then((res) => {
        if (res.status === 200) {
          alert("Crdit added successfully!");
          // Clear the form fields
          setContact("");
          setCredit("");
        } else {
          alert("Check Credentials Or Internal Error");
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <div className="bg-white">
      <Row>
        <Col>
          <Card>
            <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
              <i className="bi bi-person-fill-lock me-2"></i>
              Add Credit
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="contact">Contact</Label>
                  <Input
                    type="number"
                    id="contact"
                    className="form-control form-control-lg"
                    required
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    placeholder="Enter Contact Number"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="credit">Credit</Label>
                  <Input
                    type="number"
                    id="credit"
                    className="form-control form-control-lg"
                    required
                    value={credit}
                    onChange={(e) => setCredit(e.target.value)}
                    placeholder="Enter Credit"
                  />
                </FormGroup>
                <Button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  style={{
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    width: "100%",
                  }}
                >
                  Add Credit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddCredit;
